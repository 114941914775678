import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'

import { useAppSelector } from 'features'
import { useDocumentFormSaveButtonActions } from 'features/actions'
import { useHttp } from 'hooks/http.hook'
import { IDocumentData, IPremises, IRealizationBudgetEntry } from 'models'

type Props = {
	documentId: string
	data: IDocumentData | IRealizationBudgetEntry | IPremises | null
	updateDataFunction: (id: string, data: any) => Promise<void>
	getDataFunction: () => void
}

const DocumentFormSaveButton = ({ documentId, data, updateDataFunction, getDataFunction }: Props) => {
	const { isSaveButtonDisabled, isSaveButtonLoading } = useAppSelector(state => state.documentFormSaveButton)
	const { setIsSaveButtonDisabled, setIsSaveButtonLoading } = useDocumentFormSaveButtonActions()

	const { t } = useTranslation()
	const { showSuccessInfo } = useHttp()

	const handleDataSave = async () => {
		if (data) {
			setIsSaveButtonLoading(true)
			try {
				await updateDataFunction(documentId, data)
				getDataFunction()
				showSuccessInfo('saved')
				setIsSaveButtonDisabled(true)
			} catch (err) {
				console.error(err)
			}
		}
		setIsSaveButtonLoading(false)
	}

	return (
		<LoadingButton
			variant="contained"
			disabled={isSaveButtonDisabled}
			onClick={handleDataSave}
			loading={isSaveButtonLoading}>
			{t('general.SaveButton')}
		</LoadingButton>
	)
}

export default DocumentFormSaveButton
