import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { SettingsMenu, TooltipButton } from 'components/shared'
import { IPremises } from 'models'

import AddPremisesDialog from './AddPremisesDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	getData: () => void
	apiRef: { current: any }
	readOnly: boolean
	data: IPremises[]
	refreshDocumentData: () => void
}

const Toolbar = ({ getData, apiRef, readOnly, data, refreshDocumentData }: Props) => {
	const [isAddPremisesDialogOpen, setIsAddPremisesDialogOpen] = useState(false)

	const handleUpdateList = () => getData()

	return (
		<GridToolbarContainer className="items-center justify-end m-1.5">
			<div>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				{!readOnly && (
					<TooltipButton
						title="general.Add"
						onClick={() => setIsAddPremisesDialogOpen(true)}
						IconComponent={AddIcon}
						fontSize="medium"
					/>
				)}
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
			</div>
			{isAddPremisesDialogOpen && (
				<AddPremisesDialog
					isOpen={isAddPremisesDialogOpen}
					setIsOpen={setIsAddPremisesDialogOpen}
					refreshData={getData}
					data={data}
					refreshDocumentData={refreshDocumentData}
				/>
			)}
		</GridToolbarContainer>
	)
}

export default Toolbar
