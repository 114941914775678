import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApartmentIcon from '@mui/icons-material/Apartment'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import DescriptionIcon from '@mui/icons-material/Description'
import PaidIcon from '@mui/icons-material/Paid'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { MenuItem } from '@mui/material'

import ActionsMenu from 'components/shared/actions/ActionsMenu'
import Attachments from 'components/shared/attachments/Attachments'
import DocumentsTab from 'components/shared/documents-tab/DocumentsTab'
import PageNavigation from 'components/shared/page-navigation/PageNavigation'
import { useAppSelector } from 'features'
import { usePermissions } from 'hooks'
import { IAction } from 'models'

import { BalancingDocuments } from '../balancing-documents'
import Contracts from '../contracts/Contracts'
import { CreditNotes } from '../credit-notes'
import { DepositPayments } from '../deposit-payments'
import InvestorProcessingProtocols from '../investor-processing-protocols/InvestorProcessingProtocols'
import { CorrectionInvoices } from '../invoices'
import CostInvoices from '../invoices/cost-invoices/CostInvoices'
import InvestorSalesInvoices from '../invoices/investor-sales-invoices/InvestorSalesInvoices'
import MaterialInvoices from '../invoices/material-invoices/MaterialInvoices'
import Orders from '../orders/Orders'
import { PremisesList } from '../premises-list'
import ProcessingProtocols from '../processing-protocols/ProcessingProtocols'
import ConfirmDeleteProjectDialog from './ConfirmDeleteProjectDialog'
import CreateInvestorProtocolDialog from './CreateInvestorProtocolDialog'
import ProjectBasicInfo from './ProjectBasicInfo'
import InvestorBudgetTab from './budget-tabs/InvestorBudgetTab'
import RealizationBudgetTab from './budget-tabs/RealizationBudgetTab'
import { ServiceBudget } from './service-budget'

const Project = () => {
	const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)
	const [isCreateInvestorProtocolDialogOpen, setIsCreateInvestorProtocolDialogOpen] = useState(false)

	const { isGranted } = usePermissions()

	const { projectGlobalData } = useAppSelector(state => state.projectData)

	const params = useParams()
	const id = params.id as string
	const readOnly = isGranted('ProjectEditor', id) === false

	const isServiceBudgetDisabled =
		!projectGlobalData || (projectGlobalData.Status !== 'Service' && projectGlobalData.Status !== 'Finished')

	const { t } = useTranslation('project')

	const beforeInvokeAction = (e: any, action: IAction) => {
		switch (action.Name) {
			case t('Actions.Delete'):
				setIsConfirmDeleteDialogOpen(true)
				break
			case t('Actions.CreateInvestorProcessingProtocol'):
				setIsCreateInvestorProtocolDialogOpen(true)
				break
			default:
				return
		}
	}

	const actions = [
		{
			Name: t('Actions.CreateInvestorProcessingProtocol'),
			Enabled: !readOnly,
			AfterAction: 'Refresh',
		},
		{
			Name: t('Actions.Delete'),
			Enabled: !readOnly,
			AfterAction: 'Close',
		},
	]

	const actionsNode = (
		<ActionsMenu>
			{actions?.map(action => (
				<MenuItem key={action.Name} disabled={!action.Enabled} onClick={e => beforeInvokeAction(e, action)}>
					{action.Name}
				</MenuItem>
			))}
		</ActionsMenu>
	)

	const documentTabData = [
		{
			label: t('ProcessingProtocols', { ns: 'navigation' }),
			renderValue: <ProcessingProtocols projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('InvestorProcessingProtocols', { ns: 'navigation' }),
			renderValue: <InvestorProcessingProtocols projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('Contracts', { ns: 'navigation' }),
			renderValue: <Contracts projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('Orders', { ns: 'navigation' }),
			renderValue: <Orders projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('CostInvoices', { ns: 'navigation' }),
			renderValue: <CostInvoices projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('MaterialInvoices', { ns: 'navigation' }),
			renderValue: <MaterialInvoices projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('CorrectionInvoices', { ns: 'navigation' }),
			renderValue: <CorrectionInvoices projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('InvestorSalesInvoices', { ns: 'navigation' }),
			renderValue: <InvestorSalesInvoices projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('BalancingDocuments', { ns: 'navigation' }),
			renderValue: <BalancingDocuments projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('CreditNotes', { ns: 'navigation' }),
			renderValue: <CreditNotes projectId={id} isTab />,
			isVisible: true,
		},
		{
			label: t('PaymentOfDeposits', { ns: 'navigation' }),
			renderValue: <DepositPayments projectId={id} isTab />,
			isVisible: true,
		},
	]

	const tabs = useMemo(
		() => [
			{
				id: 1,
				tabName: t('BasicInfromation'),
				icon: <TextSnippetIcon />,
				component: <ProjectBasicInfo projectId={id} readOnly={readOnly} />,
				disabled: false,
			},
			{
				id: 2,
				tabName: t('ImplementantationBudget'),
				icon: <PaidIcon />,
				component: <RealizationBudgetTab projectId={id} readOnly={readOnly} />,
				disabled: false,
			},
			{
				id: 3,
				tabName: t('InvestorBudget'),
				icon: <PaidIcon />,
				component: <InvestorBudgetTab projectId={id} readOnly={readOnly} />,
				disabled: false,
			},
			{
				id: 4,
				tabName: t('ServiceBudget'),
				icon: <PaidIcon />,
				component: <ServiceBudget projectId={id} />,
				disabled: isServiceBudgetDisabled,
			},
			{
				id: 5,
				tabName: t('Premises', { ns: 'routes' }),
				icon: <ApartmentIcon />,
				component: <PremisesList projectId={id} />,
				disabled: !isGranted('PremisesReader'),
			},
			{
				id: 6,
				tabName: t('Documents'),
				icon: <DescriptionIcon />,
				component: <DocumentsTab tabs={documentTabData} />,
				disabled: false,
			},
			{
				id: 7,
				tabName: t('Attachments'),
				icon: <AttachFileIcon />,
				component: <Attachments contextId={id} project />,
				disabled: false,
			},
		],
		[id, readOnly, t, isServiceBudgetDisabled]
	)

	if (id == null) {
		return null
	}

	return (
		<>
			<PageNavigation tabs={tabs} actionsNode={actionsNode} />
			{isConfirmDeleteDialogOpen && (
				<ConfirmDeleteProjectDialog
					isOpen={isConfirmDeleteDialogOpen}
					setIsOpen={setIsConfirmDeleteDialogOpen}
					projectId={id}
				/>
			)}
			{isCreateInvestorProtocolDialogOpen && (
				<CreateInvestorProtocolDialog
					isOpen={isCreateInvestorProtocolDialogOpen}
					setIsOpen={setIsCreateInvestorProtocolDialogOpen}
					projectId={id}
				/>
			)}
		</>
	)
}

export default Project
