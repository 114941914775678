import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'

import { useTabs } from 'hooks'
import { usePremisesService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	premisesId: string
}

const ConfirmDeletePremisesDialog = ({ isOpen, setIsOpen, premisesId }: Props) => {
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation('premises')

	const mainTabs = useTabs()
	const navigate = useNavigate()

	const { deletePremises, showSuccessInfo } = usePremisesService()

	const handleCloseDialog = () => {
		setIsOpen(false)
	}

	const handleDeletePremises = async () => {
		try {
			setButtonLoading(true)
			await deletePremises(premisesId)
			showSuccessInfo('deleted')
			mainTabs.closeTab(mainTabs.selectedTab?.key as string)
			navigate('/premises')
		} catch (err) {
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{t('Other.RemovePremises')}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box sx={{ textAlign: 'center', mt: '2px' }}>{t('Other.DeletePremisesWarning')}</Box>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<Button variant="outlined" color="error" onClick={handleCloseDialog} disabled={buttonLoading}>
					{t('general.No', {
						ns: 'translation',
					})}
				</Button>
				<LoadingButton variant="contained" color={'success'} onClick={handleDeletePremises} loading={buttonLoading}>
					<span>
						{t('general.Yes', {
							ns: 'translation',
						})}
					</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default ConfirmDeletePremisesDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'center',
		padding: '10px 25px 20px 25px',
	},
}
