import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'Description',
			'Category',
			'CostType',
			'IndustryIdLabel',
			'MeasurementUnitIdLabel',
			'Amount',
			'Price',
			'Budget',
			'actions',
		],
		dimensions: {
			Description: {
				maxWidth: -1,
				minWidth: 50,
				width: 650,
				flex: 0,
			},
			Category: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			IndustryIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Budget: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'serviceTaskBudgetEntriesDataGridState'
