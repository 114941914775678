import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { LoadingButton } from '@mui/lab'
import { Box } from '@mui/material'

import { AutocompleteInputOption } from 'components/shared'
import ChangeHistory from 'components/shared/change-history/ChangeHistory'
import Loading from 'components/shared/loading/Loading'
import NoData from 'components/shared/no-data-message/NoData'
import { useProjectDataActions } from 'features'
import { IProjectModel } from 'models'
import { useProjectsBasicInfoService } from 'services'
import formatPostalCode from 'utils/formatPostalCode'

import ProjectForm from './ProjectBasicInfoForm'

interface Props {
	projectId: string
	readOnly: boolean
}

const ProjectBasicInfo = ({ projectId, readOnly }: Props) => {
	const [projectData, setProjectData] = useState<IProjectModel>()
	const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [loading, setLoading] = useState(true)
	const [reloadWarrantyData, setReloadWarrantyData] = useState(0)

	const { setProjectGlobalData } = useProjectDataActions()

	const { getProjectData, updateProjectData, showSuccessInfo, fieldsError, clearError, calculateProjectDataAtRuntime } =
		useProjectsBasicInfoService()

	const { t } = useTranslation()

	const handleProjectDataChange = useCallback(
		(e: any) => {
			isSaveButtonDisabled && setIsSaveButtonDisabled(false)

			const value = e.target.value
			const fieldName = e.target.name

			if (fieldsError.includes(fieldName)) {
				clearError(fieldName)
			}

			switch (fieldName) {
				case 'Address_PostalCode':
					setProjectData((prev: any) => {
						return { ...prev, [e.target.name]: formatPostalCode(value) }
					})
					break
				case 'WorkProgressPerc':
				case 'ProjectProgressPerc':
					if (value === '' || /^100(\.0*)?$|^(\d|[1-9]\d)(\.\d*)?$/.test(value)) {
						setProjectData((prev: any) => {
							return { ...prev, [fieldName]: value }
						})
					}
					break
				default:
					setProjectData((prev: any) => {
						return { ...prev, [fieldName]: value }
					})
			}
		},
		[fieldsError, clearError, isSaveButtonDisabled]
	)

	const handleAutocompleteInputChange = (
		newValue: AutocompleteInputOption,
		valueFieldName: string,
		labelFieldName: string
	) => {
		isSaveButtonDisabled && setIsSaveButtonDisabled(false)

		setProjectData((prev: any) => {
			return {
				...prev,
				[valueFieldName]: newValue.value,
				[labelFieldName]: newValue.label,
			}
		})
	}

	const handleSaveProjectData = useCallback(async () => {
		if (projectData) {
			setButtonLoading(true)
			try {
				await updateProjectData(projectId, projectData)
				getData()
				showSuccessInfo('saved')
				setIsSaveButtonDisabled(true)
				setReloadWarrantyData(prev => prev + 1)
			} catch (err) {
				console.error(err)
			}
		}
		setButtonLoading(false)
	}, [projectId, projectData])

	const handleCalculateProjectData = useCallback(async () => {
		if (projectData) {
			try {
				const response = await calculateProjectDataAtRuntime(projectId, projectData)
				setProjectData(response)
			} catch (err) {
				console.error(err)
			}
		}
	}, [projectId, projectData])

	const getData = useCallback(async () => {
		try {
			const response = await getProjectData(projectId)
			setProjectData(response)
			setProjectGlobalData(response)
		} catch (err) {
			console.error(err)
		} finally {
			setLoading(false)
		}
	}, [projectId])

	useEffect(() => {
		getData()
	}, [projectId])

	if (loading) {
		return <Loading />
	} else if (!loading && !projectData) {
		return <NoData />
	}
	
	return (
		<>
			<Box sx={sxStyles.toolsContainer}>
				<ChangeHistory
					contextId={projectId}
					translationFile="project"
					title={`${t('Project', { ns: 'routes' })} ${projectData?.InvestmentName} ${projectData?.ProjectNumber}`}
					translationPath="ProjectFields"
				/>
				<LoadingButton
					variant="contained"
					disabled={isSaveButtonDisabled}
					onClick={handleSaveProjectData}
					loading={buttonLoading}>
					{t('general.SaveButton')}
				</LoadingButton>
			</Box>
			<Box sx={sxStyles.formContainer}>
				<ProjectForm
					projectId={projectId}
					formData={projectData}
					onInputChange={handleProjectDataChange}
					onAutocompleteChange={handleAutocompleteInputChange}
					fieldErrorsList={fieldsError}
					handleCalculateProjectData={handleCalculateProjectData}
					readOnly={readOnly}
					reloadFormData={getData}
					reloadWarrantyData={reloadWarrantyData}
				/>
			</Box>
		</>
	)
}

export default ProjectBasicInfo

const sxStyles = {
	toolsContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '15px',
		pb: '0',
	},
	formContainer: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		padding: '15px',
		pt: '0',
	},
}
