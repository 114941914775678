import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
	BalancingDocument,
	BudgetEntryTab,
	Calendar,
	CommonTask,
	CommonTasks,
	CorrectionInvoice,
	CorrectionInvoices,
	CreditNote,
	CreditNotes,
	CustomCostInvoice,
	CustomCostInvoices,
	DepositPayment,
	DepositPayments,
	IncomingLetter,
	IncomingLetters,
	MasterDocumentNumerator,
	Premises,
	PremisesContactInfoType,
	PremisesHandoverStatus,
	PremisesList,
	PremisesPartType,
	PremisesStatus,
	PremisesType,
	ServiceTask,
	ServiceTasks,
} from 'components/pages'
import Administration from 'components/pages/administration/Administration'
import WorkflowPage from 'components/pages/administration/workflow-tab/workflow-page/WorkflowPage'
import Contract from 'components/pages/contract/Contract'
import Contracts from 'components/pages/contracts/Contracts'
import DebitNote from 'components/pages/debit-note/DebitNote'
import DebitNotes from 'components/pages/debit-notes/DebitNotes'
import Demand from 'components/pages/demand/Demand'
import QuotationRequestOfferDraft from 'components/pages/demand/inquiries/offer-draft/QuotationRequestOfferDraft'
import Demands from 'components/pages/demands/Demands'
import Addresses from 'components/pages/dictionaries/addresses/Addresses'
import Contractors from 'components/pages/dictionaries/contractors/Contractors'
import Currency from 'components/pages/dictionaries/currency/Currency'
import Industries from 'components/pages/dictionaries/industries/Industries'
import MasterCategory from 'components/pages/dictionaries/master-category/MasterCategory'
import MasterRegistry from 'components/pages/dictionaries/master-registry/MasterRegistry'
import MeasurementUnits from 'components/pages/dictionaries/measurement-units/MeasurementUnits'
import PaymentMethod from 'components/pages/dictionaries/payment-method/PaymentMethod'
import VatRate from 'components/pages/dictionaries/vat-rate/VatRate'
import Synchronization from 'components/pages/erp-synchronization/Synchronization'
import GoodsReceivedNote from 'components/pages/goods-received-note/GoodsReceivedNote'
import GoodsReceivedNotes from 'components/pages/goods-received-notes/GoodsReceivedNotes'
import InvestorProcessingProtocol from 'components/pages/investor-processing-protocol/InvestorProcessingProtocol'
import InvestorProcessingProtocols from 'components/pages/investor-processing-protocols/InvestorProcessingProtocols'
import AdvanceInvoice from 'components/pages/invoices/advance-invoice/AdvanceInvoice'
import AdvanceInvoices from 'components/pages/invoices/advance-invoices/AdvanceInvoices'
import CostInvoice from 'components/pages/invoices/cost-invoice/CostInvoice'
import CostInvoices from 'components/pages/invoices/cost-invoices/CostInvoices'
import InvestorSalesInvoice from 'components/pages/invoices/investor-sales-invoice/InvestorSalesInvoice'
import InvestorSalesInvoices from 'components/pages/invoices/investor-sales-invoices/InvestorSalesInvoices'
import MaterialInvoice from 'components/pages/invoices/material-invoice/MaterialInvoice'
import MaterialInvoices from 'components/pages/invoices/material-invoices/MaterialInvoices'
import ProformaInvoice from 'components/pages/invoices/proforma-invoice/ProformaInvoice'
import ProformaInvoices from 'components/pages/invoices/proforma-invoices/ProformaInvoices'
import SalesInvoice from 'components/pages/invoices/sales-invoice/SalesInvoice'
import SalesInvoices from 'components/pages/invoices/sales-invoices/SalesInvoices'
import MyAccount from 'components/pages/my-account/MyAccount'
import Offer from 'components/pages/offer/Offer'
import Offers from 'components/pages/offers/Offers'
import Order from 'components/pages/order/Order'
import Orders from 'components/pages/orders/Orders'
import ProcessingProtocol from 'components/pages/processing-protocol/ProcessingProtocol'
import ProcessingProtocols from 'components/pages/processing-protocols/ProcessingProtocols'
import Project from 'components/pages/project/Project'
import ProjectDraft from 'components/pages/project/ProjectDraft'
import Projects from 'components/pages/projects/Projects'
import User from 'components/pages/user/User'
import Welcome from 'components/pages/welcome/Welcome'

export interface IRoute {
	Component: ReactNode
	text: string
	path: string
}

const useAppRoutes = () => {
	const { t } = useTranslation('routes')

	const routes: Array<IRoute> = useMemo(
		() => [
			{
				Component: <Welcome />,
				text: t('WelcomeCard'),
				path: '/',
			},
			{
				Component: <ProjectDraft />,
				text: t('NewProject'),
				path: '/project/draft',
			},
			{
				Component: <Project />,
				text: t('Project'),
				path: '/project/:id',
			},
			{
				Component: <Projects />,
				text: t('Projects'),
				path: '/projects',
			},
			{
				Component: <Demands />,
				text: t('Demands'),
				path: '/demands',
			},
			{
				Component: <Demand />,
				text: t('Demand'),
				path: '/demand/:id',
			},
			{
				Component: <Administration />,
				text: t('Administration'),
				path: '/administration',
			},
			{
				Component: <Contractors />,
				text: t('ContractorsDictionary'),
				path: '/dictionaries/contractors',
			},
			{
				Component: <MeasurementUnits />,
				text: t('MeasurementUnitsDictionary'),
				path: '/dictionaries/measurement',
			},
			{
				Component: <VatRate />,
				text: t('VATRate'),
				path: '/dictionaries/vatRate',
			},
			{
				Component: <Industries />,
				text: t('Industries'),
				path: '/dictionaries/industries',
			},
			{
				Component: <MasterDocumentNumerator />,
				text: t('MasterDocumentNumerator'),
				path: '/dictionaries/masterDocumentNumerator',
			},
			{
				Component: <MyAccount />,
				text: t('MyAccount'),
				path: '/mysettings',
			},
			{
				Component: <User />,
				text: t('User'),
				path: '/user/:id',
			},
			{
				Component: <QuotationRequestOfferDraft />,
				text: t('Inquiry'),
				path: '/quotationRequestOfferDraft/:id',
			},
			{
				Component: <Offers />,
				text: t('Offers'),
				path: '/offers',
			},
			{
				Component: <Offer />,
				text: t('Offer'),
				path: '/offer/:id',
			},
			{
				Component: <Contracts />,
				text: t('Contracts'),
				path: '/contracts',
			},
			{
				Component: <Contract />,
				text: t('Contract'),
				path: '/contract/:id',
			},
			{
				Component: <Orders />,
				text: t('Orders'),
				path: '/orders',
			},
			{
				Component: <Order />,
				text: t('Order'),
				path: '/order/:id',
			},
			{
				Component: <ProcessingProtocols />,
				text: t('ProcessingProtocols'),
				path: '/processingProtocols',
			},
			{
				Component: <ProcessingProtocol />,
				text: t('ProcessingProtocol'),
				path: '/processingProtocol/:id',
			},
			{
				Component: <DebitNotes />,
				text: t('DebitNotes'),
				path: '/debitNotes',
			},
			{
				Component: <DebitNote />,
				text: t('DebitNote'),
				path: '/debitNote/:id',
			},
			{
				Component: <CreditNotes />,
				text: t('CreditNotes'),
				path: '/creditNotes',
			},
			{
				Component: <CreditNote />,
				text: t('CreditNote'),
				path: '/creditNote/:id',
			},
			{
				Component: <Currency />,
				text: t('CurrencyDictionary'),
				path: '/dictionaries/currency',
			},
			{
				Component: <Addresses />,
				text: t('Addresses'),
				path: '/dictionaries/addresses',
			},
			{
				Component: <PaymentMethod />,
				text: t('PaymentMethod'),
				path: '/dictionaries/paymentMethod',
			},
			{
				Component: <CostInvoices />,
				text: t('CostInvoices'),
				path: '/costInvoices',
			},
			{
				Component: <CostInvoice />,
				text: t('CostInvoice'),
				path: '/costInvoice/:id',
			},
			{
				Component: <GoodsReceivedNotes />,
				text: t('GoodsReceivedNotes'),
				path: '/goodsReceivedNotes',
			},
			{
				Component: <GoodsReceivedNote />,
				text: t('GoodsReceivedNote'),
				path: '/goodsReceivedNote/:id',
			},
			{
				Component: <MaterialInvoices />,
				text: t('MaterialInvoices'),
				path: '/materialInvoices',
			},
			{
				Component: <MaterialInvoice />,
				text: t('MaterialInvoice'),
				path: '/materialInvoice/:id',
			},
			{
				Component: <AdvanceInvoices />,
				text: t('ContractAdvanceInvoices'),
				path: '/contractAdvanceInvoices',
			},
			{
				Component: <AdvanceInvoice />,
				text: t('ContractAdvanceInvoice'),
				path: '/contractAdvanceInvoice/:id',
			},
			{
				Component: <ProformaInvoices />,
				text: t('ContractProformaInvoices'),
				path: '/contractProformaInvoices',
			},
			{
				Component: <ProformaInvoice />,
				text: t('ContractProformaInvoice'),
				path: '/contractProformaInvoice/:id',
			},
			{
				Component: <InvestorProcessingProtocols />,
				text: t('InvestorProcessingProtocols'),
				path: '/investorProcessingProtocols',
			},
			{
				Component: <InvestorProcessingProtocol />,
				text: t('InvestorProcessingProtocol'),
				path: '/investorProcessingProtocol/:id',
			},
			{
				Component: <SalesInvoices />,
				text: t('ContractSalesInvoices'),
				path: '/contractSalesInvoices',
			},
			{
				Component: <SalesInvoice />,
				text: t('ContractSalesInvoice'),
				path: '/contractSalesInvoice/:id',
			},
			{
				Component: <InvestorSalesInvoices />,
				text: t('SalesInvoices'),
				path: '/salesInvoices',
			},
			{
				Component: <InvestorSalesInvoice />,
				text: t('SalesInvoice'),
				path: '/salesInvoice/:id',
			},
			{
				Component: <Synchronization />,
				text: t('Synchronization'),
				path: '/connector',
			},
			{
				Component: <WorkflowPage />,
				text: t('WorkflowPage'),
				path: '/workflow/:id',
			},
			{
				Component: <MasterCategory />,
				text: t('MasterCategory'),
				path: '/dictionaries/masterCategory',
			},
			{
				Component: <MasterRegistry />,
				text: t('MasterRegistry'),
				path: '/dictionaries/masterRegistry',
			},
			{
				Component: <CustomCostInvoices />,
				text: t('CustomCostInvoices'),
				path: '/customCostInvoices',
			},
			{
				Component: <CustomCostInvoice />,
				text: t('CustomCostInvoice'),
				path: '/customCostInvoice/:id',
			},
			{
				Component: <IncomingLetters />,
				text: t('IncomingLetters'),
				path: '/incomingLetters',
			},
			{
				Component: <IncomingLetter />,
				text: t('IncomingLetter'),
				path: '/incomingLetter/:id',
			},
			{
				Component: <CorrectionInvoices />,
				text: t('CorrectionInvoices'),
				path: '/correctionInvoices',
			},
			{
				Component: <CorrectionInvoice />,
				text: t('CorrectionInvoice'),
				path: '/correctionInvoice/:id',
			},
			{
				Component: <BalancingDocument />,
				text: t('BalancingDocument'),
				path: '/balancingDocument/:id',
			},
			{
				Component: <CommonTask />,
				text: t('CommonTask'),
				path: '/commonTask/:id',
			},
			{
				Component: <CommonTasks />,
				text: t('CommonTasks'),
				path: '/commonTasks',
			},
			{
				Component: <Calendar />,
				text: t('Calendar'),
				path: '/calendar',
			},
			{
				Component: <BudgetEntryTab />,
				text: t('BudgetTabGeneral.GroupingColumn', { ns: 'project' }),
				path: '/budgetEntry/:id',
			},
			{
				Component: <PremisesStatus />,
				text: t('PremisesStatus'),
				path: '/dictionaries/premisesStatus',
			},
			{
				Component: <PremisesHandoverStatus />,
				text: t('PremisesHandoverStatus'),
				path: '/dictionaries/premisesHandoverStatus',
			},
			{
				Component: <PremisesType />,
				text: t('PremisesType'),
				path: '/dictionaries/premisesType',
			},
			{
				Component: <PremisesPartType />,
				text: t('PremisesPartType'),
				path: '/dictionaries/premisesPartType',
			},
			{
				Component: <PremisesContactInfoType />,
				text: t('PremisesContactInfoType'),
				path: '/dictionaries/premisesContactInfoType',
			},
			{
				Component: <PremisesList />,
				text: t('Premises'),
				path: '/premises',
			},
			{
				Component: <Premises />,
				text: t('SinglePremises'),
				path: '/premises/:id',
			},
			{
				Component: <DepositPayments />,
				text: t('PaymentOfDeposits'),
				path: '/paymentOfDeposits',
			},
			{
				Component: <DepositPayment />,
				text: t('PaymentOfDeposit'),
				path: '/paymentOfDeposit/:id',
			},
			{
				Component: <ServiceTasks />,
				text: t('ServiceTasks'),
				path: '/serviceTasks',
			},
			{
				Component: <ServiceTask />,
				text: t('ServiceTask'),
				path: '/serviceTask/:id',
			},
		],
		[t]
	)

	const defaultRoute = '/'

	return { routes, defaultRoute }
}

export default useAppRoutes
