export const BudgetEntryCategoryDataSource: Array<{
	label: string
	value: string | number
}> = [
	{ label: 'Undefined', value: 'Undefined' },
	{ label: 'Default', value: 'Default' },
	{ label: 'NotIncluded', value: 'NotIncluded' },
	{ label: 'Annexes', value: 'Annexes' },
	{ label: 'Service', value: 'Service' },
]
