import { ChangeEventHandler, SyntheticEvent } from 'react'

import { AutocompleteInputOption } from 'components'
import { useAppSelector, useDocumentFormSaveButtonActions, usePremisesActions } from 'features'
import { useHttp } from 'hooks/http.hook'

const usePremisesDataChange = () => {
	const { premisesData } = useAppSelector(state => state.premises)
	const { isSaveButtonDisabled } = useAppSelector(state => state.documentFormSaveButton)

	const { clearError, fieldsError } = useHttp()
	const { updatePremisesData } = usePremisesActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()

	const handleSaveButtonStateChange = (fieldName: string, value: any) => {
		if (value && (premisesData as any)[fieldName] != value && isSaveButtonDisabled) {
			setIsSaveButtonDisabled(false)
		}
	}

	const handleErrorStateChange = (fieldName: string) => {
		if (fieldsError.includes(fieldName)) {
			clearError(fieldName)
		}
	}

	const handleInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
		const target = e.target as HTMLInputElement

		const value = target.value
		const fieldName = target.name

		updatePremisesData([{ fieldName, value }])

		handleSaveButtonStateChange(fieldName, value)
		handleErrorStateChange(fieldName)
	}

	const handleCheckboxChange = (e: SyntheticEvent<HTMLInputElement>) => {
		const target = e.target as HTMLInputElement

		const value = target.checked
		const fieldName = target.name

		if (fieldsError.includes(fieldName)) {
			clearError(fieldName)
		}
		updatePremisesData([{ fieldName, value }])

		if ((premisesData as any)[fieldName] != value && isSaveButtonDisabled) {
			setIsSaveButtonDisabled(false)
		}
	}

	const handleAutocompleteInputChange = (
		newValue: AutocompleteInputOption,
		valueFieldName: string,
		labelFieldName: string
	) => {
		updatePremisesData([
			{ fieldName: valueFieldName, value: newValue.value },
			{ fieldName: labelFieldName, value: newValue.label },
		])

		handleSaveButtonStateChange(valueFieldName, newValue.value)
	}

	return { handleInputChange, handleCheckboxChange, handleAutocompleteInputChange, fieldsError }
}

export default usePremisesDataChange
