import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { ContactPersons, ContractorForm, InputHint, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IDepositPayment } from 'models'
import { DEPOSIT_PAYMENT_ENDPOINT } from 'services'
import { FormatNumberInput } from 'utils/FormatNumberInput'

type Props = {
	id: string
	formData: IDepositPayment
}

const Form = ({ id, formData }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('deposit-payment')

	const { fieldsError, handleInputChange } = useFormDataChange()

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem(TAB_LOCAL_STORAGE_NAME, newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('BasicDocumentTabs.BasicInformationFormTabs.Contact', { ns: 'translation' }),
				renderValue: (
					<ContactPersons
						documentId={id}
						contractorId={formData.Contractor || ''}
						endpoint={DEPOSIT_PAYMENT_ENDPOINT}
					/>
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem(TAB_LOCAL_STORAGE_NAME)
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="PaymentOfDeposit.Number" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DocumentDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentDate') && (
							<TextField
								disabled={!isEditable('DocumentDate')}
								value={formData.DocumentDate || ''}
								name="DocumentDate"
								label={t('Fields.DocumentDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="PaymentOfDeposit.DocumentDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* SendDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SendDate') && (
							<TextField
								disabled={!isEditable('SendDate')}
								value={formData.SendDate || ''}
								name="SendDate"
								label={t('Fields.SendDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('SendDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="PaymentOfDeposit.SendDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="PaymentOfDeposit.StageId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
										<InputHint translationPath="PaymentOfDeposit.WorkflowId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
											<InputHint translationPath="PaymentOfDeposit.Project" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* Contract */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Contract') && (
							<TextField
								disabled={true}
								value={formData.ContractLabel || ''}
								name="Contract"
								label={t('Fields.Contract')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.contract}
												documentId={formData.Contract}
												documentLabel={formData.ContractLabel}
											/>
											<InputHint translationPath="PaymentOfDeposit.Contract" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{!mobileView && (
						<Grid lg={3} md={3} sm={6} xs={3}>
							{/* Empty Item */}
						</Grid>
					)}
					{/* SecurityDepositAmountNWKChange  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SecurityDepositAmountNWKChange') && (
							<TextField
								disabled={!isEditable('SecurityDepositAmountNWKChange')}
								value={formData.SecurityDepositAmountNWKChange || ''}
								name="SecurityDepositAmountNWKChange"
								label={t('Fields.SecurityDepositAmountNWKChange')}
								error={fieldsError.includes('SecurityDepositAmountNWKChange')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="PaymentOfDeposit.SecurityDepositAmountNWKChange" />
										</InputAdornment>
									),
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* SecurityDepositAmountUWiUChange  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SecurityDepositAmountUWiUChange') && (
							<TextField
								disabled={!isEditable('SecurityDepositAmountUWiUChange')}
								value={formData.SecurityDepositAmountUWiUChange || ''}
								name="SecurityDepositAmountUWiUChange"
								label={t('Fields.SecurityDepositAmountUWiUChange')}
								error={fieldsError.includes('SecurityDepositAmountUWiUChange')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="PaymentOfDeposit.SecurityDepositAmountUWiUChange" />
										</InputAdornment>
									),
								}}
								fullWidth
							/>
						)}
					</Grid>
					{/* SecurityDepositPaymentValue  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('SecurityDepositPaymentValue') && (
							<TextField
								disabled={!isEditable('SecurityDepositPaymentValue')}
								value={formData.SecurityDepositPaymentValue || ''}
								name="SecurityDepositPaymentValue"
								label={t('Fields.SecurityDepositPaymentValue')}
								error={fieldsError.includes('SecurityDepositPaymentValue')}
								onChange={handleInputChange}
								InputProps={{
									inputComponent: FormatNumberInput as any,
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="PaymentOfDeposit.SecurityDepositPaymentValue" />
										</InputAdornment>
									),
								}}
								fullWidth
							/>
						)}
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={id}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
				{/* Comments */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('Comments') && (
						<TextField
							disabled={!isEditable('Comments')}
							value={formData.Comments || ''}
							name="Comments"
							label={t('Fields.Comments')}
							error={fieldsError.includes('Comments')}
							onChange={handleInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="PaymentOfDeposit.Comments" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
			</Grid>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={el.label} label={el.label} value={index.toString()} />
								}
								return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						}
						return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default Form

const TAB_LOCAL_STORAGE_NAME = 'DEPOSIT_PAYMENT_FORM_SELECTED_TAB'
