import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { SERVICE_TASK_ENDPOINT } from 'services/data'

const useServiceTaskContractEntriesService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = `${SERVICE_TASK_ENDPOINT}/contractEntries`

	const getServiceTaskContractEntries = useCallback(
		async (documentId: string) => {
			const response = await request(`${path}/${documentId}`)
			return response
		},
		[request]
	)

	const getFilteredServiceTaskContractEntries = useCallback(
		async (documentId: string, data?: GridInitialState) => {
			const response = await request(`${path}/getPossible/${documentId}`, 'POST', data)
			return response
		},
		[request]
	)

	const addServiceTaskContractEntries = useCallback(
		async (documentId: string, contractEntryId: string) => {
			const response = await request(`${path}/${documentId}?contractEntryId=${contractEntryId}`, 'PUT')
			return response
		},
		[request]
	)

	const deleteServiceTaskContractEntries = useCallback(
		async (documentId: string, contractEntryId: string) => {
			const response = await request(`${path}/${documentId}?contractEntryId=${contractEntryId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getServiceTaskContractEntries,
		getFilteredServiceTaskContractEntries,
		addServiceTaskContractEntries,
		deleteServiceTaskContractEntries,
	}
}

export default useServiceTaskContractEntriesService
