import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'Name',
			'ContractIdLabel',
			'CostType',
			'MeasurementUnitIdLabel',
			'Amount',
			'Price',
			'Value',
			'SecurityDepositAmountNWK',
			'SecurityDepositAmountUWiU',
			'Parameters',
			'BudgetEntryId',
			'actions',
		],
		dimensions: {
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 650,
				flex: 0,
			},
			ContractIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			MeasurementUnitIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Value: {
				maxWidth: -1,
				minWidth: 50,
				width: 300,
			},
			SecurityDepositAmountNWK: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			SecurityDepositAmountUWiU: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			Parameters: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			BudgetEntryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 70,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'serviceTaskContractEntriesSelectDataGridState'
