import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremisesPartType } from 'models'

const usePremisesPartTypeService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/premisesPartType'

	const getPremisesPartTypeList = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addPremisesPartType = useCallback(
		async (data: IPremisesPartType) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSinglePremisesPartType = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updatePremisesPartType = useCallback(
		async (id: string, body: IPremisesPartType) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deletePremisesPartType = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremisesPartTypeList,
		addPremisesPartType,
		getSinglePremisesPartType,
		updatePremisesPartType,
		deletePremisesPartType,
	}
}

export default usePremisesPartTypeService
