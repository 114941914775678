import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { permissionsApi } from 'apis'

export type Permission =
	| 'Administrator'
	| 'RealizationBudgetEditor'
	| 'CreateAccounts'
	| 'DictionaryEdit'
	| 'ProjectEditor'
	| 'ProjectReader'
	| 'AttachmentReader'
	| 'AttachmentEditor'
	| 'DictionaryEditContractor'
	| 'WorkflowAdministrator'
	| 'DocumentReader'
	| 'DocumentEditor'
	| 'InvestorBudgetEditor'
	| 'BusinessAdministrator'
	| 'ConnectorAdministrator'
	| 'ConnectorSynchronizationStatusReader'
	| 'ProjectList'
	| 'RealizationBudgetDemandedValueEditor'
	| 'PremisesEditor'
	| 'PremisesReader'
	| 'DictionaryEditPremises'

export interface IPermissionContext {
	Global: {
		Permissions: Array<Permission>
	}
	Projects: [
		{
			ProjectId: string
			Permissions: Array<Permission>
		},
	]
}

interface IPermissionContextData {
	permissionContext?: IPermissionContext
	isGranted: (permission: Permission, projectId?: string | null) => boolean
	refreshPermissions: () => void
}

const PermissionsContext = createContext<IPermissionContextData>({
	isGranted: () => true,
	refreshPermissions: () => {},
})

export const usePermissions = () => useContext(PermissionsContext) as IPermissionContextData

interface IPermissionsProviderProps {
	children: JSX.Element
}

export const PermissionsProvider = ({ children }: IPermissionsProviderProps) => {
	const [permissionContext, setPermissionContext] = useState<IPermissionContext>()

	const isGranted = useCallback(
		(permission: Permission, projectId?: string | null) => {
			if (permissionContext == null) return false

			// this will be probably first api call to server, so it can indicate proxy or routing problems
			if (permissionContext instanceof String) {
				throw new Error('Server responded with unexpected message. It can indicate proxy or routing problems.')
			}

			if (projectId != null) {
				const projectContext = permissionContext.Projects.find(p => p.ProjectId === projectId)
				if (projectContext && projectContext.Permissions.find(p => p === permission) != null) {
					return true
				}
			}

			const requiredPermission = permissionContext.Global.Permissions.find(p => p === permission)
			return requiredPermission != null
		},
		[permissionContext]
	)

	const refreshPermissions = useCallback(async () => {
		try {
			setPermissionContext(await permissionsApi.myPermissions())
		} catch {
			console.error('Wystąpił błąd podczas połączenia', 'error', 3000)
		}
	}, [])

	useEffect(() => {
		refreshPermissions()
	}, [])

	return (
		<PermissionsContext.Provider value={{ permissionContext, isGranted, refreshPermissions }}>
			{children}
		</PermissionsContext.Provider>
	)
}
