import { useTranslation } from 'react-i18next'

import { InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { InputHint } from 'components/shared'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IServiceTask } from 'models'

type Props = {
	formData: IServiceTask
}

const Descriptions = ({ formData }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('service-task')

	const { fieldsError, handleInputChange } = useFormDataChange()

	return (
		<div>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* ReportedDescription */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('ReportedDescription') && (
						<TextField
							disabled={!isEditable('ReportedDescription')}
							value={formData.ReportedDescription || ''}
							name="ReportedDescription"
							label={t('Fields.ReportedDescription')}
							error={fieldsError.includes('ReportedDescription')}
							onChange={handleInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ServiceTask.ReportedDescription" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{/* DefectDescription */}
				<Grid xs={12} sm={12} md={6} lg={6}>
					{isVisible('DefectDescription') && (
						<TextField
							disabled={!isEditable('DefectDescription')}
							value={formData.DefectDescription || ''}
							name="DefectDescription"
							label={t('Fields.DefectDescription')}
							error={fieldsError.includes('DefectDescription')}
							onChange={handleInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ServiceTask.DefectDescription" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
				{/* Description */}
				<Grid xs={12} sm={12} md={12} lg={12}>
					{isVisible('Description') && (
						<TextField
							disabled={!isEditable('Description')}
							value={formData.Description || ''}
							name="Description"
							label={t('Fields.Description')}
							error={fieldsError.includes('Description')}
							onChange={handleInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ServiceTask.Description" />
									</InputAdornment>
								),
							}}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	)
}

export default Descriptions
