import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	aggregation: {
		model: {
			Value: 'sum',
			CumulativelyValue: 'sum',
			LastPeriodValue: 'sum',
			CurrentPeriodValue: 'sum',
			LeftValue: 'sum',
		},
	},
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {
			actions: true,
		},
		orderedFields: [
			'__tree_data_group__',
			'Name',
			'CostType',
			'MeasurementUnitId',
			'Amount',
			'Price',
			'Value',
			'CumulativelyAmount',
			'CumulativelyValue',
			'CumulativelyPerc',
			'LastPeriodAmount',
			'LastPeriodValue',
			'LastPeriodPerc',
			'CurrentPeriodAmount',
			'CurrentPeriodValue',
			'CurrentPeriodPerc',
			'LeftAmount',
			'LeftValue',
			'LeftPerc',
			'SecurityDepositAmountToSaveNWK',
			'SecurityDepositAmountToSaveUWiU',
			'BudgetEntryId',
			'actions',
		],
		dimensions: {
			Name: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitId: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Value: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			CumulativelyAmount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			CumulativelyValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			CumulativelyPerc: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			LastPeriodAmount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			LastPeriodValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			LastPeriodPerc: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			CurrentPeriodAmount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			CurrentPeriodValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			CurrentPeriodPerc: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			LeftAmount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			LeftValue: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			LeftPerc: {
				maxWidth: -1,
				minWidth: 50,
				width: 150,
			},
			SecurityDepositAmountToSaveNWK: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			SecurityDepositAmountToSaveUWiU: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
			},
			BudgetEntryId: {
				maxWidth: -1,
				minWidth: 50,
				width: 170,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 100,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
}

export const CONTEXT_STATE_NAME = 'processingProtocolsEntriesDataGridState'
