import React from 'react'
import { useTranslation } from 'react-i18next'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Tooltip } from '@mui/material'

import clsx from 'clsx'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

type Props = {
	translationPath: string
	select?: boolean
	style?: React.CSSProperties // for components (like MUI Dialog) where tailwind CSS doesn't work
}

const InputHint = React.memo(({ translationPath, select = false, style }: Props) => {
	const { t, i18n } = useTranslation('hints')

	const text = t(translationPath)
	const isTranslationExists = i18n.exists(translationPath, { ns: 'hints' })

	if (!isTranslationExists) return null

	return (
		<Tooltip title={<TooltipTextWrapper title={text} />} placement="top" arrow>
			<span
				className={clsx('flex items-center', {
					'mr-5': select,
				})}
				style={style}>
				<InfoOutlinedIcon className="cursor-help" color="disabled" />
			</span>
		</Tooltip>
	)
})

export default InputHint
