import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApartmentIcon from '@mui/icons-material/Apartment'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import CommentIcon from '@mui/icons-material/Comment'
import PaidIcon from '@mui/icons-material/Paid'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'

import { Loading, PageNavigation } from 'components/shared'
import Attachments from 'components/shared/attachments/Attachments'
import Discussion from 'components/shared/comments/Discussion'
import DocumentWorkflowTab from 'components/shared/document-workflow-tab/DocumentWorkflowTab'
import { useAppSelector, useDocumentDataActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import { IServiceTask } from 'models'
import { useServiceTaskService } from 'services'

import { BasicInfo } from './basic-info'
import { BudgetEntries } from './budget-entries'
import ContractEntries from './contract-entries'
import { Premises } from './premises'

const ServiceTask = () => {
	const { loading, documentData, visibleProperties, editableProperties } = useAppSelector(state => state.documentData)

	const { isEditable, isVisible } = useFieldsPermissions()

	const params = useParams()

	const id = params.id as string

	const { t } = useTranslation(['translation', 'service-task'])

	const { getServiceTask, getEditableProperties, getVisibleProperties, getActions, invokeAction, errorStatus } =
		useServiceTaskService()

	const {
		setDocumentId,
		setDocumentData,
		setActionsList,
		updateLoading,
		updateFormLoading,
		setEditableProperties,
		setVisibleProperties,
	} = useDocumentDataActions()

	const getData = async () => {
		try {
			updateFormLoading(true)
			const data = await getServiceTask(id)
			const actions = await getActions(id)
			const visiblePropertiesData = await getVisibleProperties(id)
			const editablePropertiesData = await getEditableProperties(id)

			setDocumentData(data)
			setActionsList(actions)
			setVisibleProperties(visiblePropertiesData)
			setEditableProperties(editablePropertiesData)
		} catch (err) {
			console.error(err)
		} finally {
			updateFormLoading(false)
			updateLoading(false)
		}
	}

	const tabs = useMemo(() => {
		if (!documentData || !editableProperties || !visibleProperties) {
			return []
		}

		const data = documentData as IServiceTask

		return [
			{
				id: 0,
				tabName: t('BasicDocumentTabs.BasicInformation'),
				icon: <TextSnippetIcon />,
				component: <BasicInfo id={id} />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('Premises', { ns: 'routes' }),
				icon: <ApartmentIcon />,
				component: <Premises documentId={id} readOnly={!isEditable('Premises')} />,
				disabled: !data.IsPremisesSetted && data.IsBudgetSetted,
			},
			{
				id: 2,
				tabName: t('BasicDocumentTabs.ContractEntries'),
				icon: <WysiwygIcon />,
				component: <ContractEntries documentId={id} readOnly={!isEditable('ContractEntries')} />,
				disabled: !data.IsPremisesSetted && data.IsBudgetSetted,
			},
			{
				id: 3,
				tabName: t('BasicDocumentTabs.BudgetEntries'),
				icon: <PaidIcon />,
				component: <BudgetEntries documentId={id} readOnly={!isEditable('DirectBudgetEntries')} />,
				disabled: !data.IsBudgetSetted && data.IsPremisesSetted,
			},
			{
				id: 4,
				tabName: t('BasicDocumentTabs.Comments'),
				icon: <CommentIcon />,
				component: <Discussion discussionId={documentData.DiscussionId} readOnly={!isEditable('DiscussionId')} />,
				disabled: !isVisible('DiscussionId'),
			},
			{
				id: 5,
				tabName: t('BasicDocumentTabs.Workflow'),
				icon: <PublishedWithChangesIcon />,
				component: (
					<DocumentWorkflowTab workflowId={documentData.WorkflowId as string} documentId={id} refreshData={getData} />
				),
				disabled: false,
			},
			{
				id: 6,
				tabName: t('BasicDocumentTabs.Attachments'),
				icon: <AttachFileIcon />,
				component: <Attachments contextId={id} />,
				disabled: false,
			},
		]
	}, [id, t, documentData])

	useEffect(() => {
		getData()
		setDocumentId(id)
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<PageNavigation
			tabs={tabs}
			invokeActionFunction={invokeAction}
			getDataFunction={getData}
			document
			errorStatus={errorStatus}
		/>
	)
}

export default ServiceTask
