import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { SettingsMenu, TooltipButton } from 'components/shared'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	getData: () => void
	apiRef: { current: any }
}

const Toolbar = ({ getData, apiRef }: Props) => {
	return (
		<GridToolbarContainer className="items-center justify-end m-1.5">
			<div>
				<TooltipButton title="general.Refresh" onClick={getData} IconComponent={SyncIcon} fontSize="medium" />
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
			</div>
		</GridToolbarContainer>
	)
}

export default Toolbar