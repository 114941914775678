import { forwardRef } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import { IEntriesAnnexesState } from 'models'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: IEntriesAnnexesState) => void
	refreshEntriesData: () => void
	children: React.ReactNode
	title: string
}

const PremisesDialog = ({ isOpen, setIsOpen, refreshEntriesData, children, title }: Props) => {
	const handleCloseDialog = () => {
		setIsOpen({
			isOpen: false,
			id: '',
			row: null,
		})
		refreshEntriesData()
	}

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{title}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '90px',
				}}>
				<Box sx={{ display: 'flex', gap: '40px', flexDirection: 'column' }}>{children}</Box>
			</DialogContent>
		</Dialog>
	)
}

export default PremisesDialog
