import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Box,
	Chip,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Stack,
	TextField,
	Tooltip,
} from '@mui/material'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { paymentTimeFromType } from 'data'
import { IContractorModel } from 'models'
import { useContactInfoService, useIndustriesService } from 'services'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import ContractorsContactInfo from './ContractorsContactInfo'
import BankAccounts from './bank-accounts/BankAccounts'

const sxStyles = {
	gridItem: {
		p: '10px 0',
	},
	typography: {
		p: '5px 10px',
	},
	gridContainer: {
		margin: '0 0 5px 0',
	},
	emptyItem: {
		height: '76px',
	},
}

export interface Props {
	formData: IContractorModel
	handleInputChange: (e: any) => void
	isForEditDialog: boolean
	contractorsContactInfo?: any
	getContractorsContactInfo?: () => void
	setFormData: Dispatch<SetStateAction<IContractorModel>>
	handleImportDataFromGus?: (value: string) => void
}

const ContractorForm = ({
	handleInputChange,
	formData,
	isForEditDialog,
	contractorsContactInfo,
	getContractorsContactInfo,
	setFormData,
	handleImportDataFromGus,
}: Props) => {
	const [personsArray, setPersonsArray] = useState<{ value: string; label: string }[]>([])
	const [industriesArray, setIndustriesArray] = useState<any>([])
	const [allIndustriesIds, setAllIndustriesIds] = useState<any>([])

	const { t } = useTranslation('dictionaries')

	const { getPersons } = useContactInfoService()
	const { getIndustries } = useIndustriesService()

	const theme = useTheme()
	const mobileView = useMediaQuery(theme.breakpoints.down('sm'))

	const handleIndustriiesChange = (e: any) => {
		let singleIndustriesElement
		const newIndustries = e.target.value.map((el: any) => {
			singleIndustriesElement = industriesArray.find((item: any) => item.Id === el)
			return singleIndustriesElement
		})
		setFormData(prev => {
			return { ...prev, Industries: newIndustries }
		})
	}

	const handleGusClick = (searchBy: string) => {
		if (handleImportDataFromGus) {
			handleImportDataFromGus(searchBy)
		}
	}

	const getPersonsList = useCallback(async () => {
		try {
			const response = await getPersons()
			setPersonsArray(
				response.map((el: any) => {
					return { value: el.Id, label: `${el.FirstName} ${el.LastName}` }
				})
			)
		} catch (err) {
			console.error(err)
		}
	}, [getPersons])

	const getIndustriesData = useCallback(async () => {
		try {
			const response = await getIndustries()
			setIndustriesArray(
				response.map((el: any) => {
					return { Id: el.Id, Name: el.Name }
				})
			)
			setAllIndustriesIds(response.map((el: any) => el.Id))
		} catch (err) {
			console.error(err)
		}
	}, [getIndustries])

	useEffect(() => {
		getPersonsList()
		if (isForEditDialog) {
			getIndustriesData()
		}
	}, [isForEditDialog])

	return (
		<Grid container rowSpacing={3} columnSpacing={2} sx={sxStyles.gridItem}>
			<Grid
				item
				container
				rowSpacing={3}
				lg={12}
				sx={{
					...sxStyles.gridContainer,
					'& .MuiGrid-container': {
						paddingTop: '2px',
					},
				}}>
				<Grid item container rowSpacing={3} columnSpacing={2} sx={sxStyles.gridItem}>
					<Grid item lg={3} md={3} sm={6} xs={12}>
						<TextField
							value={formData?.ShortName || ''}
							id="ShortName"
							name="ShortName"
							label={t('Contractor.Fields.ShortName')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={9} md={9} sm={12} xs={12}>
						<TextField
							value={formData?.Name || ''}
							id="Name"
							name="Name"
							label={t('Contractor.Fields.Name')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={3} md={3} sm={6} xs={12}>
						<TextField
							value={formData?.NIP || ''}
							id="NIP"
							name="NIP"
							label={t('Contractor.Fields.NIP')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: formData?.NIP && (
									<Tooltip title={<TooltipTextWrapper title={t('Contractor.GetFromGUS')} />} placement="top" arrow>
										<span>
											<IconButton
												sx={{
													visibility: formData?.NIP ? 'visible' : 'hidden',
												}}
												onClick={() => handleGusClick('Nip')}>
												<Stack
													sx={{
														color: '#4caf50',
														fontWeight: '600',
														fontSize: '12px',
													}}>
													GUS
												</Stack>
											</IconButton>
										</span>
									</Tooltip>
								),
							}}
						/>
					</Grid>
					<Grid item lg={3} md={3} sm={6} xs={12}>
						<TextField
							value={formData?.NIP_Country || ''}
							id="NIP_Country"
							name="NIP_Country"
							label={t('Contractor.Fields.NIP_Country')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={3} md={3} sm={6} xs={12}>
						<TextField
							value={formData?.REGON || ''}
							id="REGON"
							name="REGON"
							label={t('Contractor.Fields.REGON')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: formData?.REGON && (
									<Tooltip title={<TooltipTextWrapper title={t('Contractor.GetFromGUS')} />} placement="top" arrow>
										<span>
											<IconButton
												sx={{
													visibility: formData?.REGON ? 'visible' : 'hidden',
												}}
												onClick={() => handleGusClick('Regon')}>
												<Stack
													sx={{
														color: '#4caf50',
														fontWeight: '600',
														fontSize: '12px',
													}}>
													GUS
												</Stack>
											</IconButton>
										</span>
									</Tooltip>
								),
							}}
						/>
					</Grid>
					<Grid item lg={3} md={3} sm={6} xs={12}>
						<TextField
							value={formData?.KRS || ''}
							id="KRS"
							name="KRS"
							label={t('Contractor.Fields.KRS')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: formData?.KRS && (
									<Tooltip title={<TooltipTextWrapper title={t('Contractor.GetFromGUS')} />} placement="top" arrow>
										<span>
											<IconButton
												sx={{
													visibility: formData?.KRS ? 'visible' : 'hidden',
												}}
												onClick={() => handleGusClick('Krs')}>
												<Stack
													sx={{
														color: '#4caf50',
														fontWeight: '600',
														fontSize: '12px',
													}}>
													GUS
												</Stack>
											</IconButton>
										</span>
									</Tooltip>
								),
							}}
						/>
					</Grid>
					{isForEditDialog && (
						<Grid item lg={3} md={3} sm={6} xs={12}>
							<TextField
								disabled={true}
								value={formData?.MasterId || ''}
								id="MasterId"
								name="MasterId"
								label={t('Contractor.Fields.MasterId')}
								onChange={handleInputChange}
								fullWidth
							/>
						</Grid>
					)}
					<Grid item lg={3} md={3} sm={6} xs={12}>
						<TextField
							select
							value={formData?.PaymentTimeFrom || ''}
							id="PaymentTimeFrom"
							name="PaymentTimeFrom"
							label={t('Contractor.Fields.PaymentTimeFrom')}
							onChange={handleInputChange}
							fullWidth>
							{paymentTimeFromType.map((el: any, index: number) => (
								<MenuItem key={index} value={el.value}>
									{t(`Contractor.Fields.PaymentTimeFromEnums.${el.label}`)}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					{!mobileView && (
						<Grid lg={9} md={9} sm={6} xs={12}>
							{/* Empty Item */}
						</Grid>
					)}
					<Grid item lg={6} md={6} sm={9} xs={12}>
						<TextField
							value={formData?.Street || ''}
							id="Street"
							name="Street"
							label={t('Contractor.Fields.Street')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={3} md={3} sm={3} xs={12}>
						<TextField
							value={formData?.StreetNumber || ''}
							id="StreetNumber"
							name="StreetNumber"
							label={t('Contractor.Fields.StreetNumber')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={3} md={3} sm={3} xs={12}>
						<TextField
							value={formData?.FlatNumber || ''}
							id="FlatNumber"
							name="FlatNumber"
							label={t('Contractor.Fields.FlatNumber')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={4} md={4} sm={5} xs={12}>
						<TextField
							value={formData?.City || ''}
							id="City"
							name="City"
							label={t('Contractor.Fields.City')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={4} md={4} sm={3} xs={12}>
						<TextField
							value={formData?.PostalCode || ''}
							id="PostalCode"
							name="PostalCode"
							label={t('Contractor.Fields.PostalCode')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={4} md={4} sm={3} xs={12}>
						<TextField
							value={formData?.Post || ''}
							id="Post"
							name="Post"
							label={t('Contractor.Fields.Post')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={4} md={4} sm={6} xs={12}>
						<TextField
							value={formData?.PhoneNumber || ''}
							id="PhoneNumber"
							name="PhoneNumber"
							label={t('Contractor.Fields.PhoneNumber')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={4} md={4} sm={6} xs={12}>
						<TextField
							value={formData?.EMail || ''}
							id="EMail"
							name="EMail"
							label={t('Contractor.Fields.EMail')}
							onChange={handleInputChange}
							fullWidth
						/>
					</Grid>
					<Grid item lg={4} md={4} sm={6} xs={0}></Grid>
					{isForEditDialog && (
						<Grid item lg={12} md={12} sm={12} xs={12}>
							{industriesArray.length > 0 && (
								<FormControl fullWidth>
									<InputLabel id="industries-label">{t('Contractor.Fields.Industries')}</InputLabel>
									<Select
										labelId="industries-label"
										id="Industries"
										multiple
										value={formData?.Industries?.map(el => el.Id) || []}
										name="Industries"
										onChange={handleIndustriiesChange}
										input={<OutlinedInput id="select-multiple-chip" label="Branże" />}
										renderValue={selected => {
											return (
												<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
													{selected.map((value: any, index: number) => (
														<Chip key={index} label={industriesArray.find((item: any) => item.Id === value).Name} />
													))}
												</Box>
											)
										}}>
										{allIndustriesIds.map((el: any, index: number) => (
											<MenuItem key={index} value={el}>
												{industriesArray.find((item: any) => item.Id === el).Name}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							)}
						</Grid>
					)}
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<TextField
							value={formData?.Comments || ''}
							id="Comments"
							name="Comments"
							label={t('Contractor.Fields.Comments')}
							onChange={handleInputChange}
							fullWidth
							multiline
							minRows={2}
						/>
					</Grid>
					<Grid item lg={12} md={12} sm={12} xs={12}>
						<TextField
							value={formData?.SettlementComments || ''}
							id="SettlementComments"
							name="SettlementComments"
							label={t('Contractor.Fields.SettlementComments')}
							onChange={handleInputChange}
							fullWidth
							multiline
							minRows={2}
						/>
					</Grid>
					<Grid item lg={3} md={3} sm={6} xs={12}>
						<TextField
							value={formData?.KeeperId || ''}
							select
							id="KeeperId"
							name="KeeperId"
							label={t('Contractor.Fields.KeeperId')}
							onChange={handleInputChange}
							fullWidth>
							{personsArray.map((el: any, index: number) => (
								<MenuItem key={index} value={el.value}>
									{el.label}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item lg={9} md={9} sm={6} xs={0}></Grid>
					{isForEditDialog && (
						<>
							<Grid item lg={9} md={12} sm={12} xs={12}>
								<ContractorsContactInfo
									data={contractorsContactInfo}
									contractorId={formData?.Id as string}
									getContacts={getContractorsContactInfo}
								/>
							</Grid>
							<Grid item lg={12} md={12} sm={12} xs={12}>
								{formData?.Id && <BankAccounts contractorId={formData?.Id as string} />}
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ContractorForm
