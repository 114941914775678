import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, TextField } from '@mui/material'

import { IProjectModel } from 'models'
import { useProjectsListService, useServiceTaskService, useWorkflowService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
}

const AddServiceTaskDialog = ({ isOpen, setIsOpen }: Props) => {
	const [projectId, setProjectId] = useState('')
	const [workflowId, setWorkflowId] = useState('')
	const [projectsList, setProjectsList] = useState([])
	const [workflowList, setWorkflowList] = useState([])
	const [buttonLoading, setButtonLoading] = useState(false)

	const navigate = useNavigate()

	const { t } = useTranslation(['service-task', 'translation'])

	const { getListOfProjects } = useProjectsListService()
	const { getWorkflowLookupList } = useWorkflowService()
	const { addServiceTask, showSuccessInfo } = useServiceTaskService()

	const handleCloseDialog = () => {
		setIsOpen(false)
		setProjectId('')
		setWorkflowId('')
	}

	const handleCreateServiceTask = async () => {
		try {
			setButtonLoading(true)
			const response = await addServiceTask(workflowId, projectId)
			if (response) {
				navigate(`/serviceTask/${response.Id}`, {
					state: { headerPostFix: ` ${response.Number}` },
				})
			}
			showSuccessInfo('action')
			handleCloseDialog()
		} catch (err) {
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}

	const getWorkflowData = async () => {
		try {
			const response = await getWorkflowLookupList('serviceTask')

			setWorkflowList(response)
		} catch (err) {
			console.error(err)
		}
	}

	const getProjectsData = async () => {
		try {
			const response = await getListOfProjects()
			setProjectsList(
				response.map((el: IProjectModel) => {
					return {
						value: el.Id,
						label: `${el.ProjectNumber || ''} ${el.InvestmentName || ''}`,
					}
				})
			)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getWorkflowData()
		getProjectsData()
	}, [])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{t('AddServiceTaskDialog.Title')}
				<IconButton sx={{ ml: '10px' }} edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<TextField
					value={projectId}
					select
					name="projectId"
					label={t('Fields.Project')}
					onChange={e => setProjectId(e.target.value)}
					fullWidth
					sx={{ mt: '10px' }}>
					{projectsList.map((el: any) => (
						<MenuItem key={el.label} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</TextField>
				<TextField
					value={workflowId}
					select
					name="workflowId"
					label={t('Fields.WorkflowId')}
					onChange={e => setWorkflowId(e.target.value)}
					fullWidth
					sx={{ mt: '20px' }}>
					{workflowList.map((el: any) => (
						<MenuItem key={el.label} value={el.value}>
							{el.label}
						</MenuItem>
					))}
				</TextField>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					disabled={!projectId || !workflowId}
					color="success"
					onClick={handleCreateServiceTask}
					loading={buttonLoading}>
					<span>{t('general.Add', { ns: 'translation' })}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddServiceTaskDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 25px 20px 25px',
	},
}
