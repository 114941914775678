import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremisesType } from 'models'

const usePremisesTypeService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/premisesType'

	const getPremisesTypeList = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addPremisesType = useCallback(
		async (data: IPremisesType) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSinglePremisesType = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updatePremisesType = useCallback(
		async (id: string, body: IPremisesType) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deletePremisesType = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremisesTypeList,
		addPremisesType,
		getSinglePremisesType,
		updatePremisesType,
		deletePremisesType,
	}
}

export default usePremisesTypeService
