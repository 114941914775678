import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'

const useContractEntryPremisesService = () => {
	const { request, error, clearError, showSuccessInfo } = useHttp()

	// Addresses
	const addressesPath = '/api/contractEntry/premisesAddress'

	const getContractEntryPremisesAddresses = useCallback(
		async (contractEntryId: string) => {
			const response = await request(`${addressesPath}/${contractEntryId}`)
			return response
		},
		[request]
	)

	const addContractEntryPremisesAddress = useCallback(
		async (contractEntryId: string, premisesAddressId: string) => {
			const response = await request(
				`${addressesPath}/${contractEntryId}?premisesAddressId=${premisesAddressId}`,
				'PUT'
			)
			return response
		},
		[request]
	)

	const deleteContractEntryPremisesAddress = useCallback(
		async (contractEntryId: string, premisesAddressId: string) => {
			await request(`${addressesPath}/${contractEntryId}?premisesAddressId=${premisesAddressId}`, 'DELETE')
		},
		[request]
	)

	// Premises
	const premisesPath = '/api/contractEntry/premises'

	const getContractEntryPremises = useCallback(
		async (contractEntryId: string) => {
			const response = await request(`${premisesPath}/${contractEntryId}`)
			return response
		},
		[request]
	)

	const addContractEntryPremises = useCallback(
		async (contractEntryId: string, premisesId: string) => {
			const response = await request(`${premisesPath}/${contractEntryId}?premisesId=${premisesId}`, 'PUT')
			return response
		},
		[request]
	)

	const deleteContractEntryPremises = useCallback(
		async (contractEntryId: string, premisesId: string) => {
			await request(`${premisesPath}/${contractEntryId}?premisesId=${premisesId}`, 'DELETE')
		},
		[request]
	)

	return {
		request,
		error,
		clearError,
		showSuccessInfo,
		getContractEntryPremisesAddresses,
		addContractEntryPremisesAddress,
		deleteContractEntryPremisesAddress,
		getContractEntryPremises,
		addContractEntryPremises,
		deleteContractEntryPremises,
	}
}

export default useContractEntryPremisesService
