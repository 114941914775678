import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useAppSelector, useDocumentActionsStateActions, useDocumentDataActions } from 'features'
import { useTabs } from 'hooks/UseTabs'
import { useHttp } from 'hooks/http.hook'
import useHandleNavigate from 'hooks/useHandleNavigate'
import { Action, IIncomingLetter, IMaterialInvoice, InvokeAction, InvokeActionResponse } from 'models'
import { enqueueSnackbar } from 'notistack'

const useHandleDocumentActions = (
	invokeActionFunction: (data: InvokeAction) => Promise<InvokeActionResponse>,
	getDataFunction: () => void
) => {
	const { documentData } = useAppSelector(state => state.documentData)
	const { selectedDocumentType } = useAppSelector(state => state.documentActionsState)
	const { copiedDocumentData } = useAppSelector(state => state.linkDocuments)

	const { t } = useTranslation()

	const {
		setSelectedAction,
		setSelectedDocumentType,
		setIsChangeStageDialogOpen,
		setChangeStageDialogWarningText,
		setIsConfirmCancelDialogOpen,
		setIsCreateDocumentDialogOpen,
		setIsLinkDocumentDialogOpen,
		setLinkDialogDocumentName,
		setIsConfirmDeletingDialogOpen,
		setIsChangeNumberDialogOpen,
		setIsPrintDocxDialogOpen,
		setIsCreateCorrectionInvoiceDialogOpen,
		setIsAdditionalCostDialogOpen,
	} = useDocumentActionsStateActions()

	const { updateLoading } = useDocumentDataActions()

	const { handleNavigate } = useHandleNavigate()

	const { showSuccessInfo } = useHttp()

	const location = useLocation()

	const tabsHook = useTabs()

	const handleMaterialInvoiceNextStage = () => {
		const materialInvoiceData = documentData as IMaterialInvoice

		if (materialInvoiceData?.SettlementValue !== materialInvoiceData?.ValueNetto)
			setChangeStageDialogWarningText(t('Warnings.SettlementAndDocumentValueDifferent'))
	}

	const getWarningText = (requieredDocumentType: string) => {
		switch (requieredDocumentType) {
			case 'ProcessingProtocol':
				return t('LinkDocumentDialog.CopyProcessingProtocolDataFirst', { ns: 'translation' })
			case 'Order':
				return t('LinkDocumentDialog.CopyOrderDataFirst', { ns: 'translation' })
			case 'GoodsReceivedNote':
				return t('LinkDocumentDialog.CopyGoodsReceivedNoteDataFirst', { ns: 'translation' })
			case 'ProformaInvoice':
				return t('LinkDocumentDialog.CopyProformaInvoiceDataFirst', { ns: 'translation' })
			default:
				return t('LinkDocumentDialog.CopyDataFirst', { ns: 'translation' })
		}
	}

	const handleLinkTypeAction = (requieredDocumentType: string, documentTypeToGenerate?: string) => {
		if (requieredDocumentType !== copiedDocumentData.documentType) {
			enqueueSnackbar(getWarningText(requieredDocumentType), {
				variant: 'warning',
				autoHideDuration: 8000,
			})
		} else {
			setIsLinkDocumentDialogOpen(true)
			documentTypeToGenerate && setSelectedDocumentType(documentTypeToGenerate)
		}
	}

	const handleGenerateTypeAction = (documentTypeToGenerate: string, requieredDocumentType?: string) => {
		if (requieredDocumentType && requieredDocumentType !== copiedDocumentData.documentType) {
			enqueueSnackbar(getWarningText(requieredDocumentType), {
				variant: 'warning',
				autoHideDuration: 8000,
			})
		} else {
			setIsCreateDocumentDialogOpen(true)
			setSelectedDocumentType(documentTypeToGenerate)
		}
	}

	const handleGenerateCorrectionFromIncomingLetterAction = () => {
		const { Contractor } = documentData as IIncomingLetter

		if (!Contractor) {
			enqueueSnackbar(t('CreateCorrectionFromIncomingLetterDialog.NoContractorErrorMessage'), {
				variant: 'error',
				autoHideDuration: 8000,
			})
		} else {
			setSelectedDocumentType('CorrectionInvoice')
			setIsCreateCorrectionInvoiceDialogOpen(true)
		}
	}

	const handlePrepareAction = (action: Action) => {
		setSelectedAction(action)
		switch (action.Name) {
			// General actions
			case 'nextStage':
			case 'previousStage':
				setIsChangeStageDialogOpen(true)
				location.pathname.includes('materialInvoice') && handleMaterialInvoiceNextStage()
				break
			case 'cancel':
				setIsConfirmCancelDialogOpen(true)
				break
			case 'PrintToDocx':
				setIsPrintDocxDialogOpen(true)
				break

			// Cost invoice
			case 'ConnectWithProcessingProtocol':
				handleLinkTypeAction('ProcessingProtocol', 'ProcessingProtocol')
				setLinkDialogDocumentName(`${t('CostInvoice.CostInvoice', { ns: 'invoice' })} ${documentData?.Number || ''}`)
				break

			// Material invoice
			case 'AddNextGRNToMaterialInvoice':
				handleLinkTypeAction('GoodsReceivedNote')
				setLinkDialogDocumentName(
					`${t('MaterialInvoice.MaterialInvoice', { ns: 'invoice' })} ${documentData?.Number || ''}`
				)
				break
			case 'GenerateCorrectionInvoice':
				handleGenerateTypeAction('CorrectionInvoice')
				break

			// Advance invoice
			case 'ConnectAdvanceInvoiceWithProformaInvoice':
				handleLinkTypeAction('ProformaInvoice')
				setLinkDialogDocumentName(
					`${t('AdvanceInvoice.AdvanceInvoice', { ns: 'invoice' })} ${documentData?.Number || ''}`
				)
				break

			// Offer
			case 'GenerateContractFromOffer':
				handleGenerateTypeAction('Contract')
				break
			case 'GenerateOrderFromOffer':
				handleGenerateTypeAction('Order')
				break

			// Incoming letter
			case 'GenerateCostInvoiceFromIncomingLetter':
				handleGenerateTypeAction('CostInvoice')
				break
			case 'GenerateCustomCostInvoiceFromIncomingLetter':
				handleGenerateTypeAction('CustomCostInvoice')
				break
			case 'GenerateMaterialInvoiceFromIncomingLetter':
				handleGenerateTypeAction('MaterialInvoice')
				break
			case 'GenerateGoodsReceivedNoteFromIncomingLetter':
				handleGenerateTypeAction('GoodsReceivedNote', 'Order')
				break
			case 'GenerateCorrectionInvoiceFromIncomingLetter':
				handleGenerateCorrectionFromIncomingLetterAction()
				break
			case 'GenerateCreditNoteFromIncomingLetter':
				handleGenerateTypeAction('CreditNote')
				break

			// Investor's processing protocol
			case 'GenerateSalesInvoiceFromInvestorProcessingPrototocol':
				handleGenerateTypeAction('SalesInvoice')
				break

			// Processing protocol
			case 'GenerateCostInvoiceFromProcessingPrototocol':
				handleGenerateTypeAction('CostInvoice')
				break
			case 'AddDebitNoteToProcessingProtocol':
				handleGenerateTypeAction('DebitNote')
				break
			case 'GenerateContractSalesInvoiceFromProcessingPrototocol':
				handleGenerateTypeAction('ContractSalesInvoice')
				break

			// Goods received note
			case 'CreateMaterialInvoice':
				handleGenerateTypeAction('MaterialInvoice')
				break

			// Contract
			case 'GenerateProcessingProtocolForContract':
			case 'GenerateFinalProcessingProtocolForContract':
				handleGenerateTypeAction('ProcessingProtocol')
				break
			case 'CreateContractAdvanceInvoice':
				handleGenerateTypeAction('ContractAdvanceInvoice')
				break
			case 'CreateContractProformaInvoice':
				handleGenerateTypeAction('ContractProformaInvoice')
				break
			case 'GeneratePaymentOfDepositForContract':
				handleGenerateTypeAction('PaymentOfDeposit')
				break
			case 'changeDocumentNumber':
				setIsChangeNumberDialogOpen(true)
				break

			// Contract sales invoice
			case 'GenerateAdditionalCostEntry':
				setIsAdditionalCostDialogOpen(true)
				break

			// Order
			case 'GenerateGoodsReceivedNoteFromOrder':
				handleGenerateTypeAction('GoodsReceivedNote')
				break

			// Demand
			case 'DeleteDemand':
				setIsConfirmDeletingDialogOpen(true)
				break
			case 'GenerateOrderFromDemand':
				handleGenerateTypeAction('Order')
				break
			case 'ChangeWorkflowForDemand':
				handleGenerateTypeAction('Demand')
				break

			default:
				handleInvokeAction(action)
		}
	}

	const handleInvokeAction = async (action: Action, parameters?: any) => {
		if (documentData)
			try {
				if (action.Name === 'nextStage' || action.Name === 'previousStage') {
					updateLoading(true)
				}

				const response = await invokeActionFunction({
					DocumentId: documentData.Id,
					Action: action.Name,
					Parameters: parameters,
				})

				if (response.documentId && response.documentNumber) {
					handleNavigate(response, `/${selectedDocumentType}`, 'documentNumber', 20, 'documentId')
				}

				showSuccessInfo('action')

				if (action.AfterAction === 'Close') {
					tabsHook.closeTab(tabsHook.selectedTab?.key as string)
				} else if (action.AfterAction === 'Refresh') {
					getDataFunction()
				}
			} catch (err) {
				console.error(err)
			} finally {
				setSelectedAction({ Name: '', Enabled: false, AfterAction: '' })
				if (action.Name === 'nextStage' || action.Name === 'previousStage') {
					updateLoading(false)
				}
			}
	}

	return { handlePrepareAction, handleInvokeAction }
}

export default useHandleDocumentActions
