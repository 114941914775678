import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: ['__tree_data_group__', 'Description'],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'__tree_data_group__',
			'Description',
			'Category',
			'CostType',
			'IndustryIdLabel',
			'MeasurementUnitIdLabel',
			'Amount',
			'Price',
			'Budget',
			'RealizationDateStart',
			'RealizationDateEnd',
			'RealizationLengthDays',
			'SecurityDepositInvoiceRetentionValueNWK',
			'SecurityDepositInvoiceRetentionValueUWiU',
			'actions',
		],
		dimensions: {
			__tree_data_group__: {
				maxWidth: -1,
				minWidth: 130,
				width: 300,
				flex: 0,
			},
			Description: {
				maxWidth: -1,
				minWidth: 50,
				width: 400,
				flex: 0,
			},
			Category: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			CostType: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			IndustryIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			MeasurementUnitIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Amount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Price: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			Budget: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			RealizationDateStart: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			RealizationDateEnd: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			RealizationLengthDays: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
			},
			SecurityDepositInvoiceRetentionValueNWK: {
				maxWidth: -1,
				minWidth: 50,
				width: 270,
			},
			SecurityDepositInvoiceRetentionValueUWiU: {
				maxWidth: -1,
				minWidth: 50,
				width: 270,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 80,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
	aggregation: {
		model: {
			Budget: 'sum',
		},
	},
}

export const CONTEXT_STATE_NAME = 'serviceBudgetDataGridState'
export const LOCAL_STORAGE_CONFIG_KEY = 'SERVICE_BUDGET_GROUP_EXPANDED'
