import { forwardRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import Loading from 'components/shared/loading/Loading'
import { IContractorModel } from 'models'
import { useContractorsService } from 'services'

import ContractorForm from './ContractorForm'
import { useCheckContractor } from './hooks'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	getContractorsList: () => void
	data: any
	contractorsList: IContractorModel[]
}

const EditContractorDialog = ({ isOpen, setIsOpen, getContractorsList, data, contractorsList }: Props) => {
	const [formData, setFormData] = useState<IContractorModel>({})
	const [contractorsContactInfo, setContractorsContactInfo] = useState<any[]>([])
	const [buttonLoading, setButtonLoading] = useState(false)
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation(['dictionaries', 'translation'])

	const { checkIfContractorAlreadyExists } = useCheckContractor()

	const { getContractorContactInfo, updateContractor, showSuccessInfo, getContractor, importGus } =
		useContractorsService()

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target

		setFormData(prev => {
			// For NIP_Country allows letters only
			if (name === 'NIP_Country') {
				const regex = /^[A-Za-z]*$/
				if (!regex.test(value)) return prev
			}

			// For other fields
			return { ...prev, [name]: value }
		})
	}

	const handleClose = () => {
		setIsOpen(false)
		setFormData({})
	}

	const handleImportDataFromGus = async (searchBy: string) => {
		const isNewContractor = !checkIfContractorAlreadyExists(contractorsList, formData.NIP as string, formData.Id)

		if (!isNewContractor) {
			return
		}

		if (formData) {
			try {
				setLoading(true)
				await updateContractor(formData.Id as string, formData)
				await importGus(formData.Id as string, searchBy)
				const updatedContractor = await getContractor(formData.Id as string)
				setFormData(updatedContractor)
				getContractorsList()
			} catch (err) {
				console.error(err)
			}
			setLoading(false)
		}
	}

	const handleEditContractor = async () => {
		const isNewContractor = !checkIfContractorAlreadyExists(contractorsList, formData.NIP as string, formData.Id)

		if (!isNewContractor) {
			return
		}

		if (formData) {
			try {
				setButtonLoading(true)
				await updateContractor(formData.Id as string, formData)
				getContractorsList()
				showSuccessInfo('saved')
				handleClose()
			} catch (err) {
				console.error(err)
			}
			setButtonLoading(false)
		}
	}

	const getContractorsContactInfo = useCallback(async () => {
		try {
			const response = await getContractorContactInfo(data.Id as string)
			setContractorsContactInfo(response)
		} catch (err) {
			console.error(err)
		}
	}, [data.Id, getContractorContactInfo])

	useEffect(() => {
		setFormData(data)
		if (data) {
			getContractorsContactInfo()
		}
	}, [data, isOpen])

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{t('Contractor.EditDialog.Title')}
					</Typography>
					<LoadingButton
						sx={{ mr: '25px' }}
						variant="contained"
						color="success"
						disabled={loading}
						onClick={handleEditContractor}
						loading={buttonLoading}>
						{t('general.Save', { ns: 'translation' })}
					</LoadingButton>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '80px',
				}}>
				{loading ? (
					<Box
						sx={{
							height: '100%',
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
						<span>
							<Loading />
						</span>
						<Typography sx={{ mt: '15px' }}>{t('general.ImportRunning', { ns: 'translation' })}</Typography>
					</Box>
				) : (
					<ContractorForm
						formData={formData}
						handleInputChange={handleInputChange}
						isForEditDialog={true}
						contractorsContactInfo={contractorsContactInfo}
						getContractorsContactInfo={getContractorsContactInfo}
						setFormData={setFormData}
						handleImportDataFromGus={handleImportDataFromGus}
					/>
				)}
			</DialogContent>
		</Dialog>
	)
}

export default EditContractorDialog
