import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { SERVICE_BUDGET_ENDPOINT } from 'services/data'

const useServiceBudgetService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = SERVICE_BUDGET_ENDPOINT

	const getServiceBudget = useCallback(
		async (projectId: string) => {
			const response = await request(`${path}/?projectId=${projectId}`)
			return response
		},
		[request]
	)

	const getServiceBudgetEntry = useCallback(
		async (entryId: string) => {
			const response = await request(`${path}/${entryId}`)
			return response
		},
		[request]
	)

	return { error, errorStatus, fieldsError, clearError, showSuccessInfo, getServiceBudget, getServiceBudgetEntry }
}

export default useServiceBudgetService
