import { forwardRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { AppBar, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

import { IPremises } from 'models'

import { PremisesSelect } from './premises-select'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />
})

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	refreshData: () => void
	data: IPremises[]
	refreshDocumentData: () => void
}

const AddPremisesDialog = ({ isOpen, setIsOpen, refreshData, data, refreshDocumentData }: Props) => {
	const [alreadyAddedPremisesIds, setAlreadyAddedPremisesIds] = useState(data.map(el => el.Id))

	const { t } = useTranslation('premises')

	const handleCloseDialog = () => {
		setIsOpen(false)
		refreshData()
	}

	return (
		<Dialog open={isOpen} fullScreen TransitionComponent={Transition}>
			<AppBar color="default">
				<Toolbar>
					<Typography sx={{ ml: 2, flex: 1, fontSize: '20px', fontWeight: '500' }} component="div">
						{t('Other.AddSinglePremises')}
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<DialogContent
				sx={{
					bgcolor: 'background.default',
					pt: '90px',
				}}>
				<PremisesSelect
					alreadyAddedPremisesIds={alreadyAddedPremisesIds}
					setAlreadyAddedPremisesIds={setAlreadyAddedPremisesIds}
					refreshDocumentData={refreshDocumentData}
				/>
			</DialogContent>
		</Dialog>
	)
}

export default AddPremisesDialog
