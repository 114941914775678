import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Checkbox, FormControlLabel, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput, InputHint, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { usePremisesDataChange } from 'hooks'
import { IPremises, IPremisesHandoverStatus, IPremisesStatus, IPremisesType } from 'models'
import {
	usePremisesHandoverStatusService,
	usePremisesService,
	usePremisesStatusService,
	usePremisesTypeService,
} from 'services'

import { ContactInfo } from './contact-info'
import { Parts } from './parts'

type Props = {
	id: string
	formData: IPremises
	readOnly: boolean
	refreshPremisesData: () => void
}

const SELECTED_TAB_LOCAL_STORAGE_NAME = 'PREMISES_FORM_SELECTED_TAB'

const Form = ({ id, formData, readOnly, refreshPremisesData }: Props) => {
	const [premisesTypeOptions, setPremisesTypeOptions] = useState<IPremisesType[]>([])
	const [premisesStatusOptions, setPremisesStatusOptions] = useState<IPremisesStatus[]>([])
	const [premisesHandoverStatusOptions, setPremisesHandoverStatusOptions] = useState<IPremisesHandoverStatus[]>([])

	const [selectedTab, setSelectedTab] = useState('0')

	const { t } = useTranslation('premises')

	const { fieldsError } = usePremisesService()
	const { getPremisesTypeList } = usePremisesTypeService()
	const { getPremisesStatusList } = usePremisesStatusService()
	const { getPremisesHandoverStatusList } = usePremisesHandoverStatusService()

	const { autocompleteInputLoading } = useAppSelector(state => state.autocompleteInput)

	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()
	const { handleInputChange, handleCheckboxChange, handleAutocompleteInputChange } = usePremisesDataChange()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem(SELECTED_TAB_LOCAL_STORAGE_NAME, newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('Other.Parts'),
				renderValue: <Parts premisesId={id} readOnly={readOnly} refreshPremisesData={refreshPremisesData} />,
				isVisible: true,
			},
			{
				label: t('BasicDocumentTabs.BasicInformationFormTabs.Contact', { ns: 'translation' }),
				renderValue: <ContactInfo premisesId={id} readOnly={readOnly} />,
				isVisible: true,
			},
		],
		[t, formData]
	)

	const getPremisesTypeOptions = async (instanceId: string) => {
		if (premisesTypeOptions.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPremisesTypeList()

				setPremisesTypeOptions(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getPremisesStatusOptions = async (instanceId: string) => {
		if (premisesStatusOptions.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPremisesStatusList()

				setPremisesStatusOptions(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	const getPremisesHandoverStatusOptions = async (instanceId: string) => {
		if (premisesHandoverStatusOptions.length === 0 && !autocompleteInputLoading[instanceId]) {
			try {
				updateAutocompleteInputLoading({ instanceId, loading: true })
				const response = await getPremisesHandoverStatusList()

				setPremisesHandoverStatusOptions(response)
			} catch (err) {
				console.error(err)
			} finally {
				updateAutocompleteInputLoading({ instanceId, loading: false })
			}
		}
	}

	useEffect(() => {
		const savedTab = localStorage.getItem(SELECTED_TAB_LOCAL_STORAGE_NAME)
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
					{/* FlatNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.FlatNumber || ''}
							name="FlatNumber"
							label={t('Fields.FlatNumber')}
							error={fieldsError.includes('FlatNumber')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.FlatNumber" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* FlatNumberBuilding  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.FlatNumberBuilding || ''}
							name="FlatNumberBuilding"
							label={t('Fields.FlatNumberBuilding')}
							error={fieldsError.includes('FlatNumberBuilding')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.FlatNumberBuilding" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Staircase  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.Staircase || ''}
							name="Staircase"
							label={t('Fields.Staircase')}
							error={fieldsError.includes('Staircase')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.Staircase" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Floor  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.Floor || ''}
							name="Floor"
							label={t('Fields.Floor')}
							error={fieldsError.includes('Floor')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.Floor" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Area  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.Area || ''}
							name="Area"
							label={t('Fields.Area')}
							error={fieldsError.includes('Area')}
							onChange={handleInputChange}
							fullWidth
							type="number"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.Area" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* PartsCount  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.PartsCount || ''}
							name="PartsCount"
							label={t('Fields.PartsCount')}
							error={fieldsError.includes('PartsCount')}
							onChange={handleInputChange}
							fullWidth
							type="number"
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.PartsCount" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* AddressId  */}
					<Grid xs={12} sm={12} md={6} lg={6}>
						<TextField
							disabled={true}
							value={formData.AddressIdLabel || ''}
							name="AddressId"
							label={t('Fields.AddressId')}
							error={fieldsError.includes('AddressId')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.AddressId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* ProjectId  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.ProjectIdLabel || ''}
							name="ProjectId"
							label={t('Fields.ProjectId')}
							error={fieldsError.includes('ProjectId')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.project}
											documentId={formData.ProjectId}
											documentLabel={formData.ProjectIdLabel}
										/>
										<InputHint translationPath="Premises.ProjectId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* TenantChanges */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<FormControlLabel
							control={
								<Checkbox
									disabled={readOnly}
									checked={formData.TenantChanges || false}
									onChange={handleCheckboxChange}
									name="TenantChanges"
								/>
							}
							label={t('Fields.TenantChanges')}
						/>
					</Grid>
					{/* WarrantyLoss */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<FormControlLabel
							control={
								<Checkbox
									disabled={readOnly}
									checked={formData.WarrantyLoss || false}
									onChange={handleCheckboxChange}
									name="WarrantyLoss"
								/>
							}
							label={t('Fields.WarrantyLoss')}
						/>
					</Grid>
					{/* WarrantyChange */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<FormControlLabel
							control={
								<Checkbox
									disabled={readOnly}
									checked={formData.WarrantyChange || false}
									onChange={handleCheckboxChange}
									name="WarrantyChange"
								/>
							}
							label={t('Fields.WarrantyChange')}
						/>
					</Grid>
					{/* ContractualWarrantyDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.ContractualWarrantyDate || ''}
							name="ContractualWarrantyDate"
							label={t('Fields.ContractualWarrantyDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldsError.includes('ContractualWarrantyDate')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.ContractualWarrantyDate" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* IndividualWarrantyDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.IndividualWarrantyDate || ''}
							name="IndividualWarrantyDate"
							label={t('Fields.IndividualWarrantyDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldsError.includes('IndividualWarrantyDate')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.IndividualWarrantyDate" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* HandoverToInvestorDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.HandoverToInvestorDate || ''}
							name="HandoverToInvestorDate"
							label={t('Fields.HandoverToInvestorDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldsError.includes('HandoverToInvestorDate')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.HandoverToInvestorDate" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* HandoverToMainOwnerDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={readOnly}
							value={formData.HandoverToMainOwnerDate || ''}
							name="HandoverToMainOwnerDate"
							label={t('Fields.HandoverToMainOwnerDate')}
							InputLabelProps={{ shrink: true }}
							type="date"
							error={fieldsError.includes('HandoverToMainOwnerDate')}
							onChange={handleInputChange}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.HandoverToMainOwnerDate" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* PremisesTypeId */}
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData?.PremisesTypeId || '',
							label: formData?.PremisesTypeIdLabel || '',
						}}
						onOpen={() => getPremisesTypeOptions('Fields.PremisesTypeId')}
						onChange={handleAutocompleteInputChange}
						options={premisesTypeOptions}
						readOnly={readOnly}
						instanceId={'Fields.PremisesTypeId'}
						inputLabel={t('Fields.PremisesTypeId')}
						name="PremisesTypeId"
						labelFieldName="PremisesTypeIdLabel"
						hintTranslationPath="Premises.PremisesTypeId"
					/>
					{/* PremisesStatusId */}
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData?.PremisesStatusId || '',
							label: formData?.PremisesStatusIdLabel || '',
						}}
						onOpen={() => getPremisesStatusOptions('Fields.PremisesStatusId')}
						onChange={handleAutocompleteInputChange}
						options={premisesStatusOptions}
						readOnly={readOnly}
						instanceId={'Fields.PremisesStatusId'}
						inputLabel={t('Fields.PremisesStatusId')}
						name="PremisesStatusId"
						labelFieldName="PremisesStatusIdLabel"
						hintTranslationPath="Premises.PremisesStatusId"
					/>
					{/* PremisesHandoverStatusId */}
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 6, md: 3, lg: 3 }}
						initialValue={{
							value: formData?.PremisesHandoverStatusId || '',
							label: formData?.PremisesHandoverStatusIdLabel || '',
						}}
						onOpen={() => getPremisesHandoverStatusOptions('Fields.PremisesHandoverStatusId')}
						onChange={handleAutocompleteInputChange}
						options={premisesHandoverStatusOptions}
						readOnly={readOnly}
						instanceId={'Fields.PremisesHandoverStatusId'}
						inputLabel={t('Fields.PremisesHandoverStatusId')}
						name="PremisesHandoverStatusId"
						labelFieldName="PremisesHandoverStatusIdLabel"
						hintTranslationPath="Premises.PremisesHandoverStatusId"
					/>
					{/* Comment */}
					<Grid xs={12} sm={12} md={12} lg={12}>
						<TextField
							disabled={readOnly}
							value={formData.Comment || ''}
							name="Comment"
							label={t('Fields.Comment')}
							error={fieldsError.includes('Comment')}
							onChange={handleInputChange}
							multiline
							minRows={4}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="Premises.Comment" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
			</div>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default Form
