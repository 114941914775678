import { MouseEvent, useId, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import AddLinkIcon from '@mui/icons-material/AddLink'
import ApartmentIcon from '@mui/icons-material/Apartment'
import DeleteIcon from '@mui/icons-material/Delete'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import HistoryIcon from '@mui/icons-material/History'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { GridRowId, GridRowModes, GridRowParams } from '@mui/x-data-grid-premium'

import { useFieldsPermissions } from 'hooks'
import { IContractEntry, IEntriesAnnexesState } from 'models'
import { useContractEntriesService } from 'services'
import { v4 as uuidv4 } from 'uuid'

interface Props {
	params: GridRowParams
	isActionButtonDisabled: boolean
	setIsActionButtonDisabled: (value: boolean) => void
	setRowModesModel: (value: any) => void
	contractId: string
	apiRef: any
	setEntriesData: (value: any) => void
	entriesData: IContractEntry[]
	setSelectedEntryId: (value: string) => void
	setEntryDataForAnnexesDialog: (value: IEntriesAnnexesState) => void
	setEntryDataForPremisesDialog: (value: IEntriesAnnexesState) => void
	setIsChangeHistoryDialogOpen: (value: boolean) => void
	setChangeHistoryDialogData: (value: IContractEntry) => void
	handlePrepareLinkToBudgetEntry: (value: any) => void
}

const ContractEntriesActions = ({
	params,
	isActionButtonDisabled,
	setIsActionButtonDisabled,
	setRowModesModel,
	contractId,
	apiRef,
	setEntriesData,
	entriesData,
	setSelectedEntryId,
	setEntryDataForAnnexesDialog,
	setEntryDataForPremisesDialog,
	setIsChangeHistoryDialogOpen,
	setChangeHistoryDialogData,
	handlePrepareLinkToBudgetEntry,
}: Props) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

	const menuElementId = useId()

	const { isVisible } = useFieldsPermissions()

	const { t } = useTranslation(['contract', 'translation'])

	const { deleteContractEntry, showSuccessInfo } = useContractEntriesService()

	const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleCloseMenu = () => {
		setAnchorEl(null)
	}

	const handleShowHistory = (id: GridRowId, data: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(id as string)
		setChangeHistoryDialogData(data)
	}

	const handleAnnexesClick = (params: GridRowParams) => () => {
		setEntryDataForAnnexesDialog({
			isOpen: true,
			id: params.id as string,
			row: params.row,
		})
	}

	const handlePremisesClick = (params: GridRowParams) => () => {
		setEntryDataForPremisesDialog({
			isOpen: true,
			id: params.id as string,
			row: params.row,
		})
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteContractEntry(id as string)
			setEntriesData(entriesData.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const handleAddEntryAsChild = (data: any) => () => {
		apiRef.current.setRowChildrenExpansion(data.row.Id, true)
		const Id = uuidv4()

		setIsActionButtonDisabled(true)
		setEntriesData((oldRows: any) => [
			...oldRows,
			{
				Id,
				isNew: true,
				ParentId: data.id,
				CanCreatePartsOnlyWithZeroAmountAndPrice: data.row.CanCreatePartsOnlyWithZeroAmountAndPrice,
				ContractId: contractId,
				CanUpdatePrice: true,
				TreePath: [...data.row.TreePath, ''],
			},
		])
		setRowModesModel((oldModel: any) => ({
			...oldModel,
			[Id]: { mode: GridRowModes.Edit, fieldToFocus: 'Name' },
		}))
	}

	const allowAddSubentry = params.row.CanCreateParts

	const allowDeleting = params.row.CanDelete

	const allowLinkToBudget = params.row.ContractId && !params.row.ParentId

	const allowPremises = params.row.ContractId && !params.row.ParentId

	const hasAnnexes = params.row.CanAddAnnexes

	const menuOptions = [
		{
			icon: <AddIcon fontSize="small" color="primary" sx={sxStyles.menuItemIcon} />,
			text: t('general.AddPart', { ns: 'translation' }),
			handleOnClick: handleAddEntryAsChild(params),
			disabled: !allowAddSubentry,
		},
		{
			icon: <AddLinkIcon fontSize="small" color="primary" sx={sxStyles.menuItemIcon} />,
			text: t('general.LinkToBudget', { ns: 'translation' }),
			handleOnClick: () => handlePrepareLinkToBudgetEntry(params),
			disabled: !allowLinkToBudget,
		},
		{
			icon: <FilePresentIcon fontSize="small" color="primary" sx={sxStyles.menuItemIcon} />,
			text: t('general.Annexes', { ns: 'translation' }),
			handleOnClick: handleAnnexesClick(params),
			disabled: !hasAnnexes || !isVisible('Annexes'),
		},
		{
			icon: <ApartmentIcon fontSize="small" color="primary" sx={sxStyles.menuItemIcon} />,
			text: t('Premises', { ns: 'routes' }),
			handleOnClick: handlePremisesClick(params),
			disabled: !allowPremises,
		},
		{
			icon: <DeleteIcon fontSize="small" color="primary" sx={sxStyles.menuItemIcon} />,
			text: t('general.Remove', { ns: 'translation' }),
			handleOnClick: handleDeleteClick(params.id),
			disabled: !allowDeleting,
		},
		{
			icon: <HistoryIcon fontSize="small" color="primary" sx={sxStyles.menuItemIcon} />,
			text: t('ChangeHistory.ChangeHistory', { ns: 'translation' }),
			handleOnClick: handleShowHistory(params.id, params.row),
			disabled: false,
		},
	]

	return (
		<>
			<IconButton
				className="p-2"
				size="medium"
				onClick={handleOpenMenu}
				color="primary"
				disabled={isActionButtonDisabled}>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id={`item-menu-${menuElementId}`}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorEl)}
				onClose={handleCloseMenu}>
				{menuOptions.map((el, index) => {
					return (
						<MenuItem
							key={index}
							onClick={() => {
								handleCloseMenu()
								el.handleOnClick()
							}}
							disabled={el.disabled}>
							{el.icon} {el.text}
						</MenuItem>
					)
				})}
			</Menu>
		</>
	)
}

export default ContractEntriesActions

const sxStyles = {
	menuItemIcon: {
		mr: '8px',
	},
}
