import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import WarningIcon from '@mui/icons-material/Warning'
import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { IContract, IPremises, IPremisesAddress } from 'models'
import { useContractEntryPremisesService, usePremisesService } from 'services'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	updateList: () => void
	entryId: string
}

type SelectValues = {
	Id: string
	Name: string
	AddressId?: string
}

const AddPremisesDialog = ({ isOpen, setIsOpen, updateList, entryId }: Props) => {
	const [selectedPremises, setSelectedPremises] = useState<SelectValues>({ Id: '', Name: '' })
	const [listOfPremises, setListOfPremises] = useState<SelectValues[]>([])
	const [listOfAddresses, setListOfAddresses] = useState<IPremisesAddress[]>([])
	const [warning, setWarning] = useState(false)

	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['premises', 'translation'])

	const { documentData } = useAppSelector(state => state.documentData)
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()

	const { getPremises } = usePremisesService()
	const { addContractEntryPremises, getContractEntryPremisesAddresses } = useContractEntryPremisesService()

	const handleCloseDialog = () => {
		setIsCreateButtonDisabled(true)
		setWarning(false)
		setIsOpen(false)
	}

	const handlePremisesChange = (e: any) => {
		const newPremises = listOfPremises.find(el => el.Id === e.value)

		if (newPremises) {
			const isNewPremisesAlreadyAddedInAddress =
				listOfAddresses.find(el => el.Id === newPremises.AddressId) !== undefined

			setWarning(isNewPremisesAlreadyAddedInAddress)

			setSelectedPremises(newPremises)
			setIsCreateButtonDisabled(false)
		}
	}

	const handleAddPremises = async () => {
		try {
			setButtonLoading(true)
			await addContractEntryPremises(entryId, selectedPremises.Id)

			updateList()
			handleCloseDialog()
		} catch (err) {
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}

	const getPremisesData = async (instanceId: string) => {
		try {
			updateAutocompleteInputLoading({ instanceId, loading: true })
			const data = documentData as IContract
			const response = await getPremises(data.Project, '')

			const formatedPremises = response.map((el: IPremises) => ({
				Id: el.Id,
				Name: `${el.AddressIdLabel}, ${el.FlatNumber || el.FlatNumberBuilding}`,
				AddressId: el.AddressId,
			}))

			setListOfPremises(formatedPremises)
		} catch (err) {
			console.error(err)
		} finally {
			updateAutocompleteInputLoading({ instanceId, loading: false })
		}
	}

	const getAddressesData = async () => {
		try {
			const response = await getContractEntryPremisesAddresses(entryId)
			setListOfAddresses(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getAddressesData()
	}, [entryId])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{`${t('general.Add', { ns: 'translation' })} ${t('SinglePremises', { ns: 'routes' })}`}
				<IconButton sx={{ ml: '10px' }} edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid sx={{ mt: '10px' }} container rowSpacing={3} columnSpacing={2}>
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 12, md: 12, lg: 12 }}
						initialValue={{
							value: selectedPremises.Id || '',
							label: selectedPremises.Name || '',
						}}
						onOpen={() => getPremisesData('ContractEntryPremises.PremisesId')}
						onChange={handlePremisesChange}
						options={listOfPremises}
						readOnly={false}
						instanceId={'ContractEntryPremises.PremisesId'}
						inputLabel={t('SinglePremises', { ns: 'routes' })}
						name="PremisesId"
						labelFieldName="Name"
						hintTranslationPath="Premises.PremisesId"
					/>
					{warning && (
						<Box sx={sxStyles.warningMessage}>
							<WarningIcon color="warning" sx={{ mr: '15px' }} />
							{t('EntriesPremisesDialog.Warning', { ns: 'contract' })}
						</Box>
					)}
				</Grid>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					disabled={isCreateButtonDisabled}
					color="success"
					onClick={handleAddPremises}
					loading={buttonLoading}>
					<span>{t('general.Add', { ns: 'translation' })}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddPremisesDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 25px 20px 25px',
	},
	warningMessage: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		p: '10px',
		pt: '20px',
		color: 'warning.main',
	},
}
