import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremisesHandoverStatus } from 'models'

const usePremisesHandoverStatusService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/premisesHandoverStatus'

	const getPremisesHandoverStatusList = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addPremisesHandoverStatus = useCallback(
		async (data: IPremisesHandoverStatus) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSinglePremisesHandoverStatus = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updatePremisesHandoverStatus = useCallback(
		async (id: string, body: IPremisesHandoverStatus) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deletePremisesHandoverStatus = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremisesHandoverStatusList,
		addPremisesHandoverStatus,
		getSinglePremisesHandoverStatus,
		updatePremisesHandoverStatus,
		deletePremisesHandoverStatus,
	}
}

export default usePremisesHandoverStatusService
