import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremises } from 'models'
import { PREMISES_ENDPOINT } from 'services/data'

const usePremisesService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = PREMISES_ENDPOINT

	const getPremises = useCallback(
		async (projectId: string, premisesAddressId: string) => {
			const response = await request(`${path}/?projectId=${projectId}&premisesAddressId=${premisesAddressId}`)
			return response
		},
		[request]
	)

	const addPremises = useCallback(
		async (data: IPremises) => {
			await request(`${path}`, 'POST', data)
		},
		[request]
	)

	const getSinglePremises = useCallback(
		async (premisesId: string) => {
			const response = await request(`${path}/${premisesId}`)
			return response
		},
		[request]
	)

	const updatePremises = useCallback(
		async (premisesId: string, data: IPremises) => {
			await request(`${path}/${premisesId}`, 'PATCH', data)
		},
		[request]
	)

	const deletePremises = useCallback(
		async (premisesId: string) => {
			await request(`${path}/${premisesId}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremises,
		addPremises,
		getSinglePremises,
		updatePremises,
		deletePremises,
	}
}

export default usePremisesService
