import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { Loading } from 'components/shared'
import { IPremisesAddress } from 'models'
import { usePremisesAddressService } from 'services'
import FormatAddress from 'utils/FormatAdress'

import { SelectValues } from './PremisesList'
import { SELECTED_ADDRESS_SESSION_STORAGE_NAME } from './initial-state'

export type AddressAction = 'edit' | 'add' | 'delete'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	projectId: string
	updateAddressesList: (instanceId: string) => void
	setSelectedAddress: (value: SelectValues) => void
	action: AddressAction
	selectedAddress: SelectValues
}

const initialState = {
	City: '',
	PostalCode: '',
	Post: '',
	Street: '',
	StreetNumber: '',
}

const AddressDialog = ({
	isOpen,
	setIsOpen,
	projectId,
	updateAddressesList,
	setSelectedAddress,
	action,
	selectedAddress,
}: Props) => {
	const [newAddressData, setNewAddressData] = useState<IPremisesAddress>(initialState as IPremisesAddress)
	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [loading, setLoading] = useState(false)

	const { t } = useTranslation(['dictionaries', 'translation'])

	const {
		addPremisesAddress,
		getSinglePremisesAddress,
		updatePremisesAddress,
		deletePremisesAddress,
		fieldsError,
		clearError,
		showSuccessInfo,
	} = usePremisesAddressService()

	const handleAddressInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		isCreateButtonDisabled && setIsCreateButtonDisabled(false)

		const target = e.target

		const { value, name } = target

		if (fieldsError.includes(name)) {
			clearError(name)
		}

		setNewAddressData((prev: any) => {
			return { ...prev, [name]: value }
		})
	}

	const handleCloseDialog = () => {
		setIsCreateButtonDisabled(true)
		setIsOpen(false)
		setNewAddressData(initialState as IPremisesAddress)
	}

	const handleAddAddress = async () => {
		if (newAddressData) {
			try {
				setButtonLoading(true)
				const response = await addPremisesAddress({
					...newAddressData,
					ProjectId: projectId,
				})

				if (response) {
					const newAddress = response as IPremisesAddress

					const newData = { id: newAddress.Id, name: FormatAddress(newAddress), projectId }

					setSelectedAddress(newData)
					sessionStorage.setItem(SELECTED_ADDRESS_SESSION_STORAGE_NAME, JSON.stringify(newData))
					updateAddressesList('Fields.AddressId')
					showSuccessInfo('saved')
					setIsCreateButtonDisabled(true)
					handleCloseDialog()
				}
			} catch (err) {
				console.error(err)
			} finally {
				setButtonLoading(false)
			}
		}
	}

	const handleSaveAddressChanges = async () => {
		if (newAddressData) {
			try {
				setButtonLoading(true)
				await updatePremisesAddress(selectedAddress.id, newAddressData)

				setSelectedAddress({ id: newAddressData.Id, name: FormatAddress(newAddressData) })
				updateAddressesList('Fields.AddressId')
				showSuccessInfo('saved')
				setIsCreateButtonDisabled(true)
				handleCloseDialog()
			} catch (err) {
				console.error(err)
			} finally {
				setButtonLoading(false)
			}
		}
	}

	const handleDeleteAddress = async () => {
		if (selectedAddress.id) {
			try {
				setButtonLoading(true)
				await deletePremisesAddress(selectedAddress.id)

				const newData = { id: '', name: '' }

				setSelectedAddress(newData)
				sessionStorage.setItem(SELECTED_ADDRESS_SESSION_STORAGE_NAME, JSON.stringify(newData))
				updateAddressesList('Fields.AddressId')
				showSuccessInfo('deleted')
				setIsCreateButtonDisabled(true)
				handleCloseDialog()
			} catch (err) {
				console.error(err)
			} finally {
				setButtonLoading(false)
			}
		}
	}

	const getAddressData = async () => {
		if (selectedAddress.id)
			try {
				setLoading(true)
				const response = await getSinglePremisesAddress(selectedAddress.id)

				setNewAddressData(response)
			} catch (err) {
				console.error(err)
			} finally {
				setLoading(false)
			}
	}

	useEffect(() => {
		if (action === 'edit') {
			getAddressData()
		} else if (action === 'delete') {
			setIsCreateButtonDisabled(false)
		}
	}, [action, selectedAddress.id])

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{action === 'add'
					? t('Addresses.AddAddressDialog.Title')
					: action === 'delete'
						? t('Other.DeleteAddress', { ns: 'premises' })
						: t('Other.UpdateAddress', { ns: 'premises' })}
				<IconButton edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{loading ? (
					<Loading />
				) : (
					<>
						{action === 'delete' ? (
							<Box sx={{ textAlign: 'center', mt: '2px' }}>
								{t('Other.DeleteAddressWarning', { ns: 'premises' })}
								<Box sx={{ color: 'primary.main' }}>{selectedAddress.name}</Box>
							</Box>
						) : (
							<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '2px' }}>
								<Grid xs={12} sm={12} md={6} lg={6}>
									<TextField
										value={newAddressData.Street || ''}
										name="Street"
										label={t('Addresses.Street')}
										error={fieldsError.includes('Street')}
										onChange={handleAddressInputChange}
										fullWidth
									/>
								</Grid>
								<Grid xs={12} sm={12} md={6} lg={6}>
									<TextField
										value={newAddressData.StreetNumber || ''}
										name="StreetNumber"
										label={t('Addresses.StreetNumber')}
										error={fieldsError.includes('StreetNumber')}
										onChange={handleAddressInputChange}
										fullWidth
									/>
								</Grid>
								<Grid xs={12} sm={12} md={6} lg={6}>
									<TextField
										value={newAddressData.City || ''}
										name="City"
										label={t('Addresses.City')}
										error={fieldsError.includes('City')}
										onChange={handleAddressInputChange}
										fullWidth
									/>
								</Grid>
								<Grid xs={12} sm={12} md={6} lg={6}>
									<TextField
										value={newAddressData?.PostalCode || ''}
										name="PostalCode"
										label={t('Addresses.PostalCode')}
										error={fieldsError.includes('PostalCode')}
										onChange={handleAddressInputChange}
										fullWidth
										inputProps={{ maxLength: 6 }}
										placeholder="00-000"
									/>
								</Grid>
								<Grid xs={12} sm={12} md={6} lg={6}>
									<TextField
										value={newAddressData?.Post || ''}
										name="Post"
										label={t('Addresses.Post')}
										error={fieldsError.includes('Post')}
										onChange={handleAddressInputChange}
										fullWidth
									/>
								</Grid>
							</Grid>
						)}
					</>
				)}
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					disabled={isCreateButtonDisabled}
					color={action === 'delete' ? 'error' : 'success'}
					onClick={
						action === 'add' ? handleAddAddress : action === 'delete' ? handleDeleteAddress : handleSaveAddressChanges
					}
					loading={buttonLoading}>
					<span>
						{t(`${action === 'add' ? 'general.Add' : action === 'delete' ? 'general.Remove' : 'general.Save'}`, {
							ns: 'translation',
						})}
					</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddressDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 25px 20px 25px',
	},
}
