import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { Tooltip } from '@mui/material'

import clsx from 'clsx'
import dayjs from 'dayjs'
import { useHandleNavigate } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { ICalendarEvent } from 'models'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'
import prepareTitleString from 'utils/prepareTitleString'

type Props = {
	event: ICalendarEvent
}

const Event = ({ event }: Props) => {
	const { datePickerLanguage } = useLanguage()
	const { handleNavigate } = useHandleNavigate()

	const dateFrom = event.DateStart ? dayjs(event.DateStart).locale(datePickerLanguage).format('D MMMM YYYY') : ''
	const dateTo = event.DateEnd ? dayjs(event.DateEnd).locale(datePickerLanguage).format('D MMMM YYYY') : ''

	const date = `${dateFrom} ${dateFrom && dateTo && '-'} ${dateTo}`

	const handleClickEvent = () => {
		if (event.ContextItemId)
			handleNavigate(event, `/${event.ContextRootingPath}`, 'ContextItemIdLabel', 20, 'ContextItemId')
	}

	return (
		<div className={clsx('flex items-center mb-2 px-2 hover:bg-primary-main/15 bg-primary-main/5 rounded-md')}>
			<div className="pr-2">
				<TaskAltIcon fontSize="small" color={'primary'} />
			</div>
			<div className="w-full flex flex-col items-start py-3 cursor-pointer" onClick={handleClickEvent}>
				<p className="w-full pb-1 m-0">{`${event.ContextItemIdLabel || ''}`}</p>
				<p className="w-full pb-2 m-0">
					<Tooltip
						disableHoverListener={event.Description?.length < 200}
						title={<TooltipTextWrapper title={event.Description || ''} />}
						placement="left"
						arrow>
						<span>{prepareTitleString(event.Description || '', 200)}</span>
					</Tooltip>
				</p>
				<p className="w-full m-0 text-right text-xs text-gray-600 dark:text-gray-300">{date}</p>
			</div>
		</div>
	)
}

export default Event
