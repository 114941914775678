import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IProjectModel } from 'models'

type InitialState = {
	projectGlobalData: IProjectModel | null
}

const initialState: InitialState = {
	projectGlobalData: null,
}

export const projectData = createSlice({
	name: 'projectData',
	initialState,
	reducers: {
		setProjectGlobalData: (state, action: PayloadAction<IProjectModel>) => {
			state.projectGlobalData = action.payload
		},
	},
})

export const projectDataActions = projectData.actions

export default projectData.reducer
