import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremisesContactInfo } from 'models'

const usePremisesContactInfoService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = '/api/premisesContactInfo'

	const getPremisesContactInfo = useCallback(
		async (premisesId: string) => {
			const response = await request(`${path}/?premisesId=${premisesId}`)
			return response
		},
		[request]
	)

	const addPremisesContactInfo = useCallback(
		async (data: IPremisesContactInfo) => {
			const response = await request(`${path}`, 'POST', data)

			return response
		},
		[request]
	)

	const getSinglePremisesContactInfo = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updatePremisesContactInfo = useCallback(
		async (id: string, data: IPremisesContactInfo) => {
			await request(`${path}/${id}`, 'PATCH', data)
		},
		[request]
	)

	const deletePremisesContactInfo = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremisesContactInfo,
		addPremisesContactInfo,
		getSinglePremisesContactInfo,
		updatePremisesContactInfo,
		deletePremisesContactInfo,
	}
}

export default usePremisesContactInfoService
