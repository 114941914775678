import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremisesPart } from 'models'

const usePremisesPartService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = '/api/premisesPart'

	const getPremisesPart = useCallback(
		async (premisesId: string) => {
			const response = await request(`${path}?premisesId=${premisesId}`)
			return response
		},
		[request]
	)

	const addPremisesPart = useCallback(
		async (data: IPremisesPart) => {
			const response = await request(`${path}`, 'POST', data)

			return response
		},
		[request]
	)

	const getSinglePremisesPart = useCallback(
		async (premisesId: string) => {
			const response = await request(`${path}/${premisesId}`)
			return response
		},
		[request]
	)

	const updatePremisesPart = useCallback(
		async (premisesPartId: string, data: IPremisesPart) => {
			await request(`${path}/${premisesPartId}`, 'PATCH', data)
		},
		[request]
	)

	const deletePremises = useCallback(
		async (premisesPartId: string) => {
			await request(`${path}/${premisesPartId}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremisesPart,
		addPremisesPart,
		getSinglePremisesPart,
		updatePremisesPart,
		deletePremises,
	}
}

export default usePremisesPartService
