import { useState } from 'react'

import AddIcon from '@mui/icons-material/Add'
import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { SettingsMenu, TooltipButton } from 'components/shared'
import { IContractEntry } from 'models'

import AddEntryDialog from './AddEntryDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	getData: () => void
	apiRef: { current: any }
	readOnly: boolean
	data: IContractEntry[]
}

const Toolbar = ({ getData, apiRef, readOnly, data }: Props) => {
	const [isAddEntryDialogOpen, setIsAddEntryDialogOpen] = useState(false)

	const handleUpdateList = () => getData()

	return (
		<GridToolbarContainer className="items-center justify-end m-1.5">
			<div>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				{!readOnly && (
					<TooltipButton
						title="general.Add"
						onClick={() => setIsAddEntryDialogOpen(true)}
						IconComponent={AddIcon}
						fontSize="medium"
					/>
				)}
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
			</div>
			{isAddEntryDialogOpen && (
				<AddEntryDialog
					isOpen={isAddEntryDialogOpen}
					setIsOpen={setIsAddEntryDialogOpen}
					refreshData={getData}
					data={data}
				/>
			)}
		</GridToolbarContainer>
	)
}

export default Toolbar
