import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { SERVICE_TASK_ENDPOINT } from 'services/data'

const useServiceTaskPremisesService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = `${SERVICE_TASK_ENDPOINT}/premises`

	const getServiceTaskPremises = useCallback(
		async (documentId: string) => {
			const response = await request(`${path}/${documentId}`)
			return response
		},
		[request]
	)

	const addServiceTaskPremises = useCallback(
		async (documentId: string, premisesId: string) => {
			const response = await request(`${path}/${documentId}?premisesId=${premisesId}`, 'PUT')
			return response
		},
		[request]
	)

	const deleteServiceTaskPremises = useCallback(
		async (documentId: string, premisesId: string) => {
			const response = await request(`${path}/${documentId}?premisesId=${premisesId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getServiceTaskPremises,
		addServiceTaskPremises,
		deleteServiceTaskPremises,
	}
}

export default useServiceTaskPremisesService
