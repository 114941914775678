import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { AutocompleteInput } from 'components/shared'
import { useAppSelector, useAutocompleteInputActions } from 'features'
import { IContract, IPremisesAddress } from 'models'
import { useContractEntryPremisesService, usePremisesAddressService } from 'services'
import FormatAddress from 'utils/FormatAdress'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	updateList: () => void
	entryId: string
}

type SelectValues = {
	id: string
	name: string
}

const AddAddressDialog = ({ isOpen, setIsOpen, updateList, entryId }: Props) => {
	const [selectedAddress, setSelectedAddress] = useState<SelectValues>({ id: '', name: '' })
	const [listOfAddresses, setListOfAddresses] = useState<SelectValues[]>([])

	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['premises', 'translation'])

	const { documentData } = useAppSelector(state => state.documentData)
	const { updateAutocompleteInputLoading } = useAutocompleteInputActions()

	const { getPremisesAddresses } = usePremisesAddressService()
	const { addContractEntryPremisesAddress } = useContractEntryPremisesService()

	const handleCloseDialog = () => {
		setIsCreateButtonDisabled(true)
		setIsOpen(false)
	}

	const handleAddressChange = (e: any) => {
		const newAddress = { id: e.value, name: e.label }

		setSelectedAddress(newAddress)
		setIsCreateButtonDisabled(false)
	}

	const handleAddAddress = async () => {
		try {
			setButtonLoading(true)
			await addContractEntryPremisesAddress(entryId, selectedAddress.id)

			updateList()
			handleCloseDialog()
		} catch (err) {
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}

	const getAddressesData = async (instanceId: string) => {
		try {
			updateAutocompleteInputLoading({ instanceId, loading: true })
			const data = documentData as IContract
			const response = await getPremisesAddresses(data.Project)

			const formatedAddress = response.map((el: IPremisesAddress) => ({
				Id: el.Id,
				Name: FormatAddress(el),
			}))

			setListOfAddresses(formatedAddress)
		} catch (err) {
			console.error(err)
		} finally {
			updateAutocompleteInputLoading({ instanceId, loading: false })
		}
	}

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{`${t('general.Add', { ns: 'translation' })} ${t('Fields.AddressId')}`}
				<IconButton sx={{ ml: '10px' }} edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Grid sx={{ mt: '10px' }} container rowSpacing={3} columnSpacing={2}>
					<AutocompleteInput
						dimensions={{ xs: 12, sm: 12, md: 12, lg: 12 }}
						initialValue={{
							value: selectedAddress.id || '',
							label: selectedAddress.name || '',
						}}
						onOpen={() => getAddressesData('ContractEntryPremisesAddresses.AddressId')}
						onChange={handleAddressChange}
						options={listOfAddresses}
						readOnly={false}
						instanceId={'ContractEntryPremisesAddresses.AddressId'}
						inputLabel={t('Fields.AddressId')}
						name="AddressId"
						labelFieldName="Name"
						hintTranslationPath="Premises.AddressId"
					/>
				</Grid>
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					disabled={isCreateButtonDisabled}
					color="success"
					onClick={handleAddAddress}
					loading={buttonLoading}>
					<span>{t('general.Add', { ns: 'translation' })}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddAddressDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 25px 20px 25px',
	},
}
