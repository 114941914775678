import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import {
	DataGridPremium,
	GridColumnHeaderParams,
	GridRowId,
	GridRowParams,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { ColumnHeaderRender, NoData, TooltipButton } from 'components/shared'
import { BudgetCostTypeDataSource, BudgetEntryCategoryDataSource } from 'data'
import { useDocumentDataActions } from 'features'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IRealizationBudgetEntry } from 'models'
import { useServiceTaskBudgetEntriesService, useServiceTaskService } from 'services'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import Toolbar from './Toolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	documentId: string
	readOnly: boolean
}

const BudgetEntries = ({ documentId, readOnly }: Props) => {
	const [data, setData] = useState<IRealizationBudgetEntry[]>([])
	const [dataLoading, setDataLoading] = useState(true)

	const { setDocumentData } = useDocumentDataActions()

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['project', 'service-task', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { getServiceTaskBudgetEntries, deleteServiceTaskBudgetEntries, showSuccessInfo } =
		useServiceTaskBudgetEntriesService()
	const { getServiceTask } = useServiceTaskService()

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteServiceTaskBudgetEntries(documentId, id as string)

			if (data.length <= 1) {
				refreshDocumentData()
			}

			setData(prev => prev.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const columns: any = [
		{
			field: 'Description',
			headerName: t('RealizationBudgetTab.Columns.Description'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'Category',
			headerName: t('RealizationBudgetTab.Columns.Category'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.Category || 'Undefined'
			},
			valueOptions: () =>
				BudgetEntryCategoryDataSource.map((el: any) => ({
					value: el.value,
					label: t(`RealizationBudgetTab.Columns.CategoryEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = BudgetEntryCategoryDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return t(`RealizationBudgetTab.Columns.CategoryEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'CostType',
			headerName: t('RealizationBudgetTab.Columns.CostType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CostType || 'Undefined'
			},
			valueOptions: () =>
				BudgetCostTypeDataSource.map((el: any) => ({
					value: el.value,
					label: t(`RealizationBudgetTab.Columns.CostTypeEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = BudgetCostTypeDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return t(`RealizationBudgetTab.Columns.CostTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'IndustryIdLabel',
			headerName: t('RealizationBudgetTab.Columns.IndustryId'),
			headerAlign: 'center',
			align: 'center',
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'MeasurementUnitIdLabel',
			headerName: t('RealizationBudgetTab.Columns.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'Amount',
			headerName: t('RealizationBudgetTab.Columns.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'Price',
			headerName: t('RealizationBudgetTab.Columns.Price'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value, 4, 4)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'Budget',
			headerName: t('RealizationBudgetTab.Columns.Budget'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.RealizationBudget" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				if (readOnly) return []

				return [
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params.id)}
						IconComponent={DeleteIcon}
						disabled={readOnly}
					/>,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = async () => {
		try {
			setDataLoading(true)
			const response = await getServiceTaskBudgetEntries(documentId)
			setData(response)
		} catch (err) {
			console.error(err)
		} finally {
			setDataLoading(false)
		}
	}

	const refreshDocumentData = async () => {
		try {
			const data = await getServiceTask(documentId)
			setDocumentData(data)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getData()
	}, [documentId])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<DataGridPremium
			apiRef={apiRef}
			editMode="row"
			columns={orderedColumns}
			rows={data}
			getRowId={row => row.Id}
			loading={dataLoading || dataGridStateLoading}
			sx={{ minHeight: '500px', height: 'calc(100vh - 250px)', border: 'none' }}
			components={{
				Toolbar: Toolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					apiRef,
					getData,
					readOnly,
					data,
					refreshDocumentData,
				},
			}}
			initialState={INITIALSTATE}
			rowHeight={35}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default BudgetEntries
