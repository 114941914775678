import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { TooltipButton } from 'components/shared'
import { useDataGridState } from 'hooks'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	apiRef: { current: any }
}

const Toolbar = ({ apiRef }: Props) => {
	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => {
		apiRef.current.showFilterPanel()
	}

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	return (
		<GridToolbarContainer sx={{ display: 'flex', justifyContent: 'flex-end', m: '5px' }}>
			<TooltipButton
				title="general.ResetDataGridState"
				onClick={() => handleUpdateState(true)}
				IconComponent={RotateLeftIcon}
				fontSize="medium"
			/>
			<TooltipButton
				title="general.SaveDataGridState"
				onClick={() => handleUpdateState()}
				IconComponent={FactCheckOutlinedIcon}
				fontSize="medium"
			/>
			<TooltipButton title="general.Filter" onClick={handleFilter} IconComponent={FilterListIcon} fontSize="medium" />
		</GridToolbarContainer>
	)
}

export default Toolbar
