import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { ColumnsButton, ExpandCollapseButtons, SwitchTreeData, TooltipButton } from 'components/shared'
import { useDataGridState } from 'hooks'
import { IDepositPaymentEntry } from 'models'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	apiRef: { current: any }
	isActionButtonDisabled: boolean
	isTreeData: boolean
	setIsTreeData: (value: boolean) => void
	getData: () => void
	data: IDepositPaymentEntry[]
}

const Toolbar = ({ apiRef, isActionButtonDisabled, isTreeData, setIsTreeData, getData, data }: Props) => {
	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => apiRef.current.showFilterPanel()

	const handleUpdateState = (reset: boolean = false) =>
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)

	return (
		<>
			<GridToolbarContainer className="flex items-center justify-between m-1 py-2">
				<div>
					<ColumnsButton />
					<ExpandCollapseButtons apiRef={apiRef} isActionButtonDisabled={isActionButtonDisabled} />
				</div>
				<div className="flex items-center">
					<SwitchTreeData
						isActionButtonDisabled={isActionButtonDisabled}
						isTreeData={isTreeData}
						setIsTreeData={setIsTreeData}
					/>
					<TooltipButton
						title="general.ResetDataGridState"
						onClick={() => handleUpdateState(true)}
						IconComponent={RotateLeftIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.SaveDataGridState"
						onClick={() => handleUpdateState()}
						IconComponent={FactCheckOutlinedIcon}
						fontSize="medium"
					/>
					<TooltipButton
						title="general.Filter"
						onClick={handleFilter}
						IconComponent={FilterListIcon}
						disabled={isActionButtonDisabled}
						fontSize="medium"
					/>
				</div>
			</GridToolbarContainer>
		</>
	)
}

export default Toolbar
