import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IDepositPayment, IInvokeAction } from 'models'
import { DEPOSIT_PAYMENT_ENDPOINT } from 'services/data'

export interface getDepositPaymentsListProps {
	isCanceled: boolean
	isCompleted: boolean
	contractId?: string
	projectId?: string
	budgetEntryId?: string
	data?: GridInitialState
}

const useDepositPaymentService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = DEPOSIT_PAYMENT_ENDPOINT

	const getFilteredDepositPayments = useCallback(
		async ({ isCanceled, isCompleted, contractId, projectId, budgetEntryId, data }: getDepositPaymentsListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			if (budgetEntryId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&budgetEntryId=${budgetEntryId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getDepositPayments = useCallback(
		async ({ isCanceled, isCompleted, contractId, projectId }: getDepositPaymentsListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (contractId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&contractId=${contractId}`
			}

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getDepositPayment = useCallback(
		async (depositPaymentId: string) => {
			const response = await request(`${path}/${depositPaymentId}`)
			return response
		},
		[request]
	)

	const updateDepositPayment = useCallback(
		async (depositPaymentId: string, data: IDepositPayment) => {
			await request(`${path}/${depositPaymentId}`, 'PATCH', data)
		},
		[request]
	)

	const getVisibleProperties = useCallback(
		async (depositPaymentId: string) => {
			const response = await request(`${path}/allowedProperties/read/${depositPaymentId}`)
			return response
		},
		[request]
	)

	const getEditableProperties = useCallback(
		async (depositPaymentId: string) => {
			const response = await request(`${path}/allowedProperties/write/${depositPaymentId}`)
			return response
		},
		[request]
	)

	const getActions = useCallback(
		async (depositPaymentId: string) => {
			const response = await request(`${path}/actions/${depositPaymentId}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getFilteredDepositPayments,
		getDepositPayments,
		getDepositPayment,
		updateDepositPayment,
		getVisibleProperties,
		getEditableProperties,
		getActions,
		invokeAction,
	}
}

export default useDepositPaymentService
