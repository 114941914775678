import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { SERVICE_TASK_ENDPOINT } from 'services/data'

const useServiceTaskBudgetEntriesService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = `${SERVICE_TASK_ENDPOINT}/directBudgetEntries`

	const getServiceTaskBudgetEntries = useCallback(
		async (documentId: string) => {
			const response = await request(`${path}/${documentId}`)
			return response
		},
		[request]
	)

	const addServiceTaskBudgetEntries = useCallback(
		async (documentId: string, budgetEntryId: string) => {
			const response = await request(`${path}/${documentId}?budgetEntryId=${budgetEntryId}`, 'PUT')
			return response
		},
		[request]
	)

	const deleteServiceTaskBudgetEntries = useCallback(
		async (documentId: string, budgetEntryId: string) => {
			const response = await request(`${path}/${documentId}?budgetEntryId=${budgetEntryId}`, 'DELETE')
			return response
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getServiceTaskBudgetEntries,
		addServiceTaskBudgetEntries,
		deleteServiceTaskBudgetEntries,
	}
}

export default useServiceTaskBudgetEntriesService
