import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import FilterListIcon from '@mui/icons-material/FilterList'
import RotateLeftIcon from '@mui/icons-material/RotateLeft'
import { Box } from '@mui/material'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { InputHint, TooltipButton } from 'components/shared'
import { useDataGridState } from 'hooks'

import AddAddressDialog from './AddAddressDialog'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	apiRef: { current: any }
	getData: () => void
	readOnly: boolean
	entryId: string
}

const Toolbar = ({ apiRef, getData, readOnly, entryId }: Props) => {
	const [isAddAddressDialogOpen, setIsAddAddressDialogOpen] = useState(false)

	const { t } = useTranslation()

	const { handleSaveStateSnapshot } = useDataGridState()

	const handleFilter = () => apiRef.current.showFilterPanel()

	const handleUpdateState = (reset: boolean = false) => {
		handleSaveStateSnapshot(reset, apiRef, INITIALSTATE, CONTEXT_STATE_NAME)
	}

	const handleAddAddress = () => setIsAddAddressDialogOpen(true)

	return (
		<GridToolbarContainer sx={{ display: 'flex', justifyContent: 'space-between', m: '5px' }}>
			<div>
				<Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: '4px', fontSize: '16px' }}>
					{t('Addresses', { ns: 'navigation' })}
					<InputHint
						translationPath="Contract.ContractEntriesPremises.Addresses"
						style={{ display: 'flex', alignItems: 'center', paddingLeft: '4px' }}
					/>
				</Box>
			</div>
			<div>
				<TooltipButton
					title="general.ResetDataGridState"
					onClick={() => handleUpdateState(true)}
					IconComponent={RotateLeftIcon}
					fontSize="medium"
				/>
				<TooltipButton
					title="general.SaveDataGridState"
					onClick={() => handleUpdateState()}
					IconComponent={FactCheckOutlinedIcon}
					fontSize="medium"
				/>
				<TooltipButton title="general.Filter" onClick={handleFilter} IconComponent={FilterListIcon} fontSize="medium" />
				{!readOnly && (
					<TooltipButton title="general.Add" onClick={handleAddAddress} IconComponent={AddIcon} fontSize="medium" />
				)}
				{isAddAddressDialogOpen && (
					<AddAddressDialog
						isOpen={isAddAddressDialogOpen}
						setIsOpen={setIsAddAddressDialogOpen}
						updateList={getData}
						entryId={entryId}
					/>
				)}
			</div>
		</GridToolbarContainer>
	)
}

export default Toolbar
