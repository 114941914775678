import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import { LoadingButton } from '@mui/lab'
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material'

import { IPremises } from 'models'
import { usePremisesAddressService, usePremisesService } from 'services'

import { CreationType } from '../AddPremisesMenu'
import { SelectValues } from '../PremisesList'

type Props = {
	isOpen: boolean
	setIsOpen: (value: boolean) => void
	address: SelectValues
	type: CreationType
	updateList: () => void
}

const AddPremisesDialog = ({ isOpen, setIsOpen, address, type, updateList }: Props) => {
	const [premises, setPremises] = useState('')
	const [flatNumberBuilding, setFlatNumberBuilding] = useState('')
	const [premisesRange, setPremisesRange] = useState({ from: 1, to: 1 })
	const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true)
	const [buttonLoading, setButtonLoading] = useState(false)

	const { t } = useTranslation(['premises', 'translation'])

	const { addPremises, showSuccessInfo } = usePremisesService()
	const { createPremisesByList, createPremisesFromTo } = usePremisesAddressService()

	const handleCloseDialog = () => {
		setIsCreateButtonDisabled(true)
		setIsOpen(false)
	}

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		isCreateButtonDisabled && setIsCreateButtonDisabled(false)

		const { name, value } = e.target

		if (type !== 'generate') {
			setPremises(value)
		} else {
			setPremisesRange(prev => ({ ...prev, [name]: value }))
		}
	}

	const handleFlatNumberBuildingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		isCreateButtonDisabled && setIsCreateButtonDisabled(false)
		setFlatNumberBuilding(e.target.value)
	}

	const handleAddPremises = async () => {
		try {
			setButtonLoading(true)
			if (type === 'addSingle') {
				await addPremises({
					FlatNumber: premises,
					FlatNumberBuilding: flatNumberBuilding,
					ProjectId: address.projectId,
					AddressId: address.id,
				} as IPremises)
			} else if (type === 'addGroup') {
				const data = premises.split(',').map(item => item.trim())

				await createPremisesByList(address.id, data)
			} else {
				await createPremisesFromTo(address.id, premisesRange.from, premisesRange.to)
			}

			updateList()
			showSuccessInfo('saved')
			setIsCreateButtonDisabled(true)
			handleCloseDialog()
		} catch (err) {
			console.error(err)
		} finally {
			setButtonLoading(false)
		}
	}

	const getTitle = () => {
		switch (type) {
			case 'addGroup':
				return t('Other.AddPremisesAsGroupDescription')
			case 'generate':
				return t('Other.GeneratePremisesAsListDescription')

			default:
				return t('Other.AddSinglePremisesDescription')
		}
	}

	const title = getTitle()

	return (
		<Dialog open={isOpen} fullWidth>
			<DialogTitle sx={sxStyles.title}>
				{title}
				<IconButton sx={{ ml: '10px' }} edge="start" color="inherit" onClick={handleCloseDialog} aria-label="close">
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				{type === 'generate' ? (
					<Box sx={{ display: 'flex' }}>
						<TextField
							sx={{ mt: '10px' }}
							value={premisesRange.from || ''}
							name="from"
							label={t('Other.RangeFrom')}
							onChange={handleInputChange}
							type="number"
							fullWidth
						/>
						<TextField
							sx={{ mt: '10px', ml: '10px' }}
							value={premisesRange.to || ''}
							name="to"
							label={t('Other.RangeTo')}
							onChange={handleInputChange}
							type="number"
							fullWidth
						/>
					</Box>
				) : (
					<Box sx={{ display: 'flex' }}>
						<TextField
							sx={{ mt: '10px' }}
							value={premises || ''}
							name="FlatNumber"
							label={type === 'addSingle' ? t('Fields.FlatNumber') : t('Premises', { ns: 'routes' })}
							onChange={handleInputChange}
							fullWidth
						/>
						{type === 'addSingle' && (
							<TextField
								sx={{ mt: '10px', ml: '10px' }}
								value={flatNumberBuilding || ''}
								name="FlatNumberBuilding"
								label={t('Fields.FlatNumberBuilding')}
								onChange={handleFlatNumberBuildingChange}
								fullWidth
							/>
						)}
					</Box>
				)}
			</DialogContent>
			<DialogActions sx={sxStyles.actionsContainer}>
				<LoadingButton
					variant="contained"
					disabled={isCreateButtonDisabled}
					color="success"
					onClick={handleAddPremises}
					loading={buttonLoading}>
					<span>{t('general.Add', { ns: 'translation' })}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}

export default AddPremisesDialog

const sxStyles = {
	title: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		fontSize: '18px',
	},
	actionsContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		padding: '10px 25px 20px 25px',
	},
}
