import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { MenuItem } from '@mui/material'

import { Loading, PageNavigation } from 'components/shared'
import ActionsMenu from 'components/shared/actions/ActionsMenu'
import Attachments from 'components/shared/attachments/Attachments'
import { useAppSelector, usePremisesActions } from 'features'
import { usePermissions } from 'hooks'
import { IAction } from 'models'
import { usePremisesService } from 'services'

import ConfirmDeletePremisesDialog from './ConfirmDeletePremisesDialog'
import { BasicInfo } from './basic-info-tab'

const Premises = () => {
	const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false)

	const { loading, premisesData } = useAppSelector(state => state.premises)
	const { setPremisesId, setPremisesData, updateLoading, updateFormLoading } = usePremisesActions()

	const { getSinglePremises } = usePremisesService()

	const { isGranted } = usePermissions()

	const { t } = useTranslation(['translation', 'premises'])

	const readOnly = !isGranted('PremisesEditor')
	const params = useParams()
	const id = params.id as string

	const beforeInvokeAction = (e: any, action: IAction) => {
		switch (action.Name) {
			case t('Other.RemovePremises', { ns: 'premises' }):
				setIsConfirmDeleteDialogOpen(true)
				break
			default:
				return
		}
	}

	const actions = [
		{
			Name: t('Other.RemovePremises', { ns: 'premises' }),
			Enabled: !readOnly,
			AfterAction: 'Close',
		},
	]

	const actionsNode = (
		<ActionsMenu>
			{actions?.map(action => (
				<MenuItem key={action.Name} disabled={!action.Enabled} onClick={e => beforeInvokeAction(e, action)}>
					{action.Name}
				</MenuItem>
			))}
		</ActionsMenu>
	)

	const tabs = useMemo(() => {
		if (!premisesData) {
			return []
		}

		return [
			{
				id: 1,
				tabName: t('BasicDocumentTabs.BasicInformation'),
				icon: <TextSnippetIcon />,
				component: <BasicInfo id={id} readOnly={readOnly} />,
				disabled: false,
			},
			{
				id: 2,
				tabName: t('BasicDocumentTabs.Attachments'),
				icon: <AttachFileIcon />,
				component: <Attachments contextId={id} project />,
				disabled: false,
			},
		]
	}, [id, readOnly, t, premisesData])

	const getData = async () => {
		try {
			updateFormLoading(true)
			const data = await getSinglePremises(id)
			setPremisesData(data)
		} catch (err) {
			console.error(err)
		} finally {
			updateFormLoading(false)
			updateLoading(false)
		}
	}

	useEffect(() => {
		getData()
		setPremisesId(id)
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<>
			<PageNavigation tabs={tabs} actionsNode={actionsNode} />
			{isConfirmDeleteDialogOpen && (
				<ConfirmDeletePremisesDialog
					isOpen={isConfirmDeleteDialogOpen}
					setIsOpen={setIsConfirmDeleteDialogOpen}
					premisesId={id}
				/>
			)}
		</>
	)
}

export default Premises
