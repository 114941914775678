import { useCallback } from 'react'

import { GridInitialState } from '@mui/x-data-grid-premium'

import { useHttp } from 'hooks/http.hook'
import { IInvokeAction, IServiceTask } from 'models'
import { SERVICE_TASK_ENDPOINT } from 'services/data'

export interface getServiceTasksListProps {
	isCanceled: boolean
	isCompleted: boolean
	projectId?: string
	data?: GridInitialState
}

const useServiceTaskService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = SERVICE_TASK_ENDPOINT

	const getFilteredServiceTasks = useCallback(
		async ({ isCanceled, isCompleted, projectId, data }: getServiceTasksListProps) => {
			let getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}/filter?includeCanceled=${isCanceled}&includeCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`, 'POST', data)
			return response
		},
		[request]
	)

	const getServiceTasks = useCallback(
		async ({ isCanceled, isCompleted, projectId }: getServiceTasksListProps) => {
			let getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}`

			if (projectId) {
				getListPath = `${path}?isCanceled=${isCanceled}&isCompleted=${isCompleted}&projectId=${projectId}`
			}

			const response = await request(`${getListPath}`)
			return response
		},
		[request]
	)

	const getServiceTask = useCallback(
		async (taskId: string) => {
			const response = await request(`${path}/${taskId}`)
			return response
		},
		[request]
	)

	const addServiceTask = useCallback(
		async (serviceTaskWorkflowId: string, projectId: string) => {
			const response = await request(
				`${path}?serviceTaskWorkflowId=${serviceTaskWorkflowId}&projectId=${projectId}`,
				'POST'
			)
			return response
		},
		[request]
	)

	const updateServiceTask = useCallback(
		async (taskId: string, data: IServiceTask) => {
			await request(`${path}/${taskId}`, 'PATCH', data)
		},
		[request]
	)

	const getVisibleProperties = useCallback(
		async (taskId: string) => {
			const response = await request(`${path}/allowedProperties/read/${taskId}`)
			return response
		},
		[request]
	)

	const getEditableProperties = useCallback(
		async (taskId: string) => {
			const response = await request(`${path}/allowedProperties/write/${taskId}`)
			return response
		},
		[request]
	)

	const getActions = useCallback(
		async (taskId: string) => {
			const response = await request(`${path}/actions/${taskId}`)
			return response
		},
		[request]
	)

	const invokeAction = useCallback(
		async (data: IInvokeAction) => {
			const response = await request(`${path}/action`, 'POST', data)
			return response.ResultData
		},
		[request]
	)

	return {
		error,
		errorStatus,
		clearError,
		fieldsError,
		showSuccessInfo,
		getFilteredServiceTasks,
		getServiceTasks,
		getServiceTask,
		addServiceTask,
		updateServiceTask,
		getVisibleProperties,
		getEditableProperties,
		getActions,
		invokeAction,
	}
}

export default useServiceTaskService
