import { useTranslation } from 'react-i18next'

import { Box, Divider, InputAdornment, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

import { InputHint } from 'components/shared'
import { useFieldsPermissions } from 'hooks'
import { ICostInvoice } from 'models'
import { FormatNumberInput } from 'utils/FormatNumberInput'

interface Props {
	formData: ICostInvoice
	onInputChange: (e: any) => void
	fieldErrorsList: string[]
}

const Deposits = ({ formData, onInputChange, fieldErrorsList }: Props) => {
	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('invoice')

	const theme = useTheme()
	const tabletViewAndUp = useMediaQuery(theme.breakpoints.up('sm'))

	return (
		<>
			<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '5px' }}>
				{/* ValueToSettlement */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('ValueToSettlement') && (
						<TextField
							disabled={true}
							value={formData.ValueToSettlement || ''}
							name="ValueToSettlement"
							label={t('CostInvoice.Fields.ValueToSettlement')}
							error={fieldErrorsList.includes('ValueToSettlement')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="CostInvoice.ValueToSettlement" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
				{/* DepositValueNetto  */}
				<Grid xs={12} sm={6} md={3} lg={3}>
					{isVisible('DepositValueNetto') && (
						<TextField
							disabled={!isEditable('DepositValueNetto')}
							value={formData.DepositValueNetto || ''}
							name="DepositValueNetto"
							label={t('CostInvoice.Fields.DepositValueNetto')}
							error={fieldErrorsList.includes('DepositValueNetto')}
							onChange={onInputChange}
							InputProps={{
								inputComponent: FormatNumberInput as any,
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="CostInvoice.DepositValueNetto" />
									</InputAdornment>
								),
							}}
							fullWidth
						/>
					)}
				</Grid>
			</Grid>
			<Box
				sx={{
					...sxStyles.row,
					flexDirection: tabletViewAndUp ? 'row' : 'column',
				}}>
				<Box sx={tabletViewAndUp ? sxStyles.itemsContainerLeft : sxStyles.itemsContainerMobile}>
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '12px' }}>
						<Grid xs={12} sm={12} md={12} lg={12}>
							<Divider>{t('CostInvoice.BasicInformationFormTabs.Other.NWK')}</Divider>
						</Grid>
						{/* SecurityDepositAmountNWK */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountNWK') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountNWK || ''}
									name="SecurityDepositAmountNWK"
									label={t('CostInvoice.Fields.SecurityDepositAmountNWK')}
									error={fieldErrorsList.includes('SecurityDepositAmountNWK')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
										endAdornment: (
											<InputAdornment position="end">
												<InputHint translationPath="CostInvoice.SecurityDepositAmountNWK" />
											</InputAdornment>
										),
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositAmountNWKCustom */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountNWKCustom') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountNWKCustom || ''}
									name="SecurityDepositAmountNWKCustom"
									label={t('CostInvoice.Fields.SecurityDepositAmountNWKCustom')}
									error={fieldErrorsList.includes('SecurityDepositAmountNWKCustom')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
										endAdornment: (
											<InputAdornment position="end">
												<InputHint translationPath="CostInvoice.SecurityDepositAmountNWKCustom" />
											</InputAdornment>
										),
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositAmountNWKSum */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountNWKSum') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountNWKSum || ''}
									name="SecurityDepositAmountNWKSum"
									label={t('CostInvoice.Fields.SecurityDepositAmountNWKSum')}
									error={fieldErrorsList.includes('SecurityDepositAmountNWKSum')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
										endAdornment: (
											<InputAdornment position="end">
												<InputHint translationPath="CostInvoice.SecurityDepositAmountNWKSum" />
											</InputAdornment>
										),
									}}
									fullWidth
								/>
							)}
						</Grid>
					</Grid>
				</Box>
				<Box sx={tabletViewAndUp ? sxStyles.itemsContainerRight : sxStyles.itemsContainerMobile}>
					<Grid container rowSpacing={3} columnSpacing={2} sx={{ mt: '12px' }}>
						<Grid xs={12} sm={12} md={12} lg={12}>
							<Divider>{t('CostInvoice.BasicInformationFormTabs.Other.UWiU')}</Divider>
						</Grid>
						{/* SecurityDepositAmountUWiU */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountUWiU') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountUWiU || ''}
									name="SecurityDepositAmountUWiU"
									label={t('CostInvoice.Fields.SecurityDepositAmountUWiU')}
									error={fieldErrorsList.includes('SecurityDepositAmountUWiU')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
										endAdornment: (
											<InputAdornment position="end">
												<InputHint translationPath="CostInvoice.SecurityDepositAmountUWiU" />
											</InputAdornment>
										),
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositAmountUWiUCustom */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountUWiUCustom') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountUWiUCustom || ''}
									name="SecurityDepositAmountUWiUCustom"
									label={t('CostInvoice.Fields.SecurityDepositAmountUWiUCustom')}
									error={fieldErrorsList.includes('SecurityDepositAmountUWiUCustom')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
										endAdornment: (
											<InputAdornment position="end">
												<InputHint translationPath="CostInvoice.SecurityDepositAmountUWiUCustom" />
											</InputAdornment>
										),
									}}
									fullWidth
								/>
							)}
						</Grid>
						{/* SecurityDepositAmountUWiUSum */}
						<Grid xs={12} sm={12} md={6} lg={6}>
							{isVisible('SecurityDepositAmountUWiUSum') && (
								<TextField
									disabled={true}
									value={formData.SecurityDepositAmountUWiUSum || ''}
									name="SecurityDepositAmountUWiUSum"
									label={t('CostInvoice.Fields.SecurityDepositAmountUWiUSum')}
									error={fieldErrorsList.includes('SecurityDepositAmountUWiUSum')}
									onChange={onInputChange}
									InputProps={{
										inputComponent: FormatNumberInput as any,
										endAdornment: (
											<InputAdornment position="end">
												<InputHint translationPath="CostInvoice.SecurityDepositAmountUWiUSum" />
											</InputAdornment>
										),
									}}
									fullWidth
								/>
							)}
						</Grid>
					</Grid>
				</Box>
			</Box>
		</>
	)
}

export default Deposits

const sxStyles = {
	row: {
		display: 'flex',
		width: '100%',
		pb: '15px',
	},
	itemsContainerLeft: {
		width: '50%',
		mr: '8px',
	},
	itemsContainerRight: {
		width: '50%',
		ml: '8px',
	},
	itemsContainerMobile: {
		width: '100%',
		mb: '10px',
	},
}
