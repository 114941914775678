import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import AttachFileIcon from '@mui/icons-material/AttachFile'
import CommentIcon from '@mui/icons-material/Comment'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import WysiwygIcon from '@mui/icons-material/Wysiwyg'

import { Loading, PageNavigation } from 'components/shared'
import Attachments from 'components/shared/attachments/Attachments'
import Discussion from 'components/shared/comments/Discussion'
import DocumentWorkflowTab from 'components/shared/document-workflow-tab/DocumentWorkflowTab'
import { useAppSelector, useDocumentDataActions } from 'features'
import { useFieldsPermissions } from 'hooks'
import { useDepositPaymentService } from 'services'

import { BasicInfo } from './basic-info'
import { Entries } from './entries'

const DepositPayment = () => {
	const { loading, documentData, visibleProperties, editableProperties } = useAppSelector(state => state.documentData)

	const { isEditable, isVisible } = useFieldsPermissions()

	const params = useParams()

	const id = params.id as string

	const { t } = useTranslation(['translation', 'deposit-payment'])

	const { getDepositPayment, getEditableProperties, getVisibleProperties, getActions, invokeAction, errorStatus } =
		useDepositPaymentService()

	const {
		setDocumentId,
		setDocumentData,
		setActionsList,
		updateLoading,
		updateFormLoading,
		setEditableProperties,
		setVisibleProperties,
	} = useDocumentDataActions()

	const getData = async () => {
		try {
			updateFormLoading(true)
			const data = await getDepositPayment(id)
			const actions = await getActions(id)
			const visiblePropertiesData = await getVisibleProperties(id)
			const editablePropertiesData = await getEditableProperties(id)

			setDocumentData(data)
			setActionsList(actions)
			setVisibleProperties(visiblePropertiesData)
			setEditableProperties(editablePropertiesData)
		} catch (err) {
			console.error(err)
		} finally {
			updateFormLoading(false)
			updateLoading(false)
		}
	}

	const tabs = useMemo(() => {
		if (!documentData || !editableProperties || !visibleProperties) {
			return []
		}

		return [
			{
				id: 0,
				tabName: t('BasicDocumentTabs.BasicInformation'),
				icon: <TextSnippetIcon />,
				component: <BasicInfo id={id} />,
				disabled: false,
			},
			{
				id: 1,
				tabName: t('BasicDocumentTabs.Positions'),
				icon: <WysiwygIcon />,
				component: <Entries id={id} readOnly={!isEditable('Entries')} />,
				disabled: !isVisible('Entries'),
			},
			{
				id: 2,
				tabName: t('BasicDocumentTabs.Comments'),
				icon: <CommentIcon />,
				component: <Discussion discussionId={documentData.DiscussionId} readOnly={!isEditable('DiscussionId')} />,
				disabled: !isVisible('DiscussionId'),
			},
			{
				id: 3,
				tabName: t('BasicDocumentTabs.Workflow'),
				icon: <PublishedWithChangesIcon />,
				component: (
					<DocumentWorkflowTab workflowId={documentData.WorkflowId as string} documentId={id} refreshData={getData} />
				),
				disabled: false,
			},
			{
				id: 4,
				tabName: t('BasicDocumentTabs.Attachments'),
				icon: <AttachFileIcon />,
				component: <Attachments contextId={id} />,
				disabled: false,
			},
		]
	}, [id, t, documentData])

	useEffect(() => {
		getData()
		setDocumentId(id)
	}, [id])

	if (loading) {
		return <Loading />
	}

	return (
		<PageNavigation
			tabs={tabs}
			invokeActionFunction={invokeAction}
			getDataFunction={getData}
			document
			errorStatus={errorStatus}
		/>
	)
}

export default DepositPayment
