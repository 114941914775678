import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
	ChangeHistory,
	DefaultAttachmentPreviewButton,
	DocumentFormSaveButton,
	FormSkeletonLoading,
} from 'components/shared'
import DocumentStatus from 'components/shared/document-status/DocumentStatus'
import { useAppSelector, useDocumentDataActions, useDocumentFormSaveButtonActions } from 'features'
import { IServiceTask } from 'models'
import { useServiceTaskService } from 'services'

import Form from './Form'

type Props = {
	id: string
}

const BasicInfo = ({ id }: Props) => {
	const { documentData, formLoading } = useAppSelector(state => state.documentData)

	const { setDocumentData } = useDocumentDataActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { getServiceTask, updateServiceTask } = useServiceTaskService()

	const { t } = useTranslation()

	const refreshDocumentData = async () => {
		try {
			const response = await getServiceTask(id)
			setDocumentData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshDocumentData()
		setIsSaveButtonDisabled(true)
	}, [id])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={9} />
	}
	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<DocumentStatus
						IsCanceled={documentData?.IsCanceled}
						IsCompleted={documentData?.IsCompleted}
						ToCalculation={documentData?.ToCalculation}
						documentId={id}
					/>
				</div>
				<div>
					<DefaultAttachmentPreviewButton attachmentId={documentData?.DefaultAttachmentId} />
					<ChangeHistory
						contextId={id}
						translationFile="service-task"
						title={`${t('ServiceTask', { ns: 'routes' })} ${documentData?.Number}`}
						translationPath="Fields"
					/>
					<DocumentFormSaveButton
						documentId={id}
						data={documentData}
						updateDataFunction={updateServiceTask}
						getDataFunction={refreshDocumentData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<Form formData={documentData as IServiceTask} id={id} />
			</div>
		</>
	)
}

export default BasicInfo
