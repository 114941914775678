import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IDepositPaymentEntry } from 'models'

const useDepositPaymentEntriesService = () => {
	const { request, showSuccessInfo, error, clearError } = useHttp()

	const path = '/api/paymentOfDepositEntry'

	const getDepositPaymentEntries = useCallback(
		async (paymentId: string, treeData: boolean) => {
			const response = await request(`${path}?getTree=${treeData}&paymentId=${paymentId}`)
			return response
		},
		[request]
	)

	const updateDepositPaymentEntry = useCallback(
		async (entryId: string, body: IDepositPaymentEntry) => {
			await request(`${path}/${entryId}`, 'PATCH', body)
		},
		[request]
	)

	return { error, clearError, showSuccessInfo, getDepositPaymentEntries, updateDepositPaymentEntry }
}

export default useDepositPaymentEntriesService
