import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, InputAdornment, Tab, TextField } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import { ContactPersons, ContractorForm, InputHint, InputLinkButton } from 'components/shared'
import { PATHS } from 'data'
import { useFieldsPermissions, useFormDataChange } from 'hooks'
import { IServiceTask } from 'models'
import { SERVICE_TASK_ENDPOINT } from 'services'
import Descriptions from './Descriptions'

type Props = {
	id: string
	formData: IServiceTask
}

const Form = ({ id, formData }: Props) => {
	const [selectedTab, setSelectedTab] = useState('0')

	const { isEditable, isVisible } = useFieldsPermissions()

	const { t } = useTranslation('service-task')

	const { fieldsError, handleInputChange } = useFormDataChange()

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setSelectedTab(newValue)
		localStorage.setItem(TAB_LOCAL_STORAGE_NAME, newValue)
	}

	const tabs = useMemo(
		() => [
			{
				label: t('Fields.Description'),
				renderValue: <Descriptions formData={formData} />,
				isVisible: true,
			},
			{
				label: t('BasicDocumentTabs.BasicInformationFormTabs.Contact', { ns: 'translation' }),
				renderValue: (
					<ContactPersons documentId={id} contractorId={formData.Contractor || ''} endpoint={SERVICE_TASK_ENDPOINT} />
				),
				isVisible: isVisible('ContactPersons') || isVisible('ContractorContacts'),
			},
		],
		[t, formData]
	)

	useEffect(() => {
		const savedTab = localStorage.getItem(TAB_LOCAL_STORAGE_NAME)
		setSelectedTab(savedTab || '0')
	}, [])

	return (
		<>
			<div className="w-full pb-4">
				<Grid className="mt-1" container rowSpacing={3} columnSpacing={2}>
					{/* Number  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Number') && (
							<TextField
								disabled={true}
								value={formData.Number || ''}
								name="Number"
								label={t('Fields.Number')}
								error={fieldsError.includes('Number')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.Number" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* DocumentCreatedDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('DocumentCreatedDate') && (
							<TextField
								disabled={!isEditable('DocumentCreatedDate')}
								value={formData.DocumentCreatedDate || ''}
								name="DocumentCreatedDate"
								label={t('Fields.DocumentCreatedDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('DocumentCreatedDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.DocumentCreatedDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* StageId */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.StageIdLabel || ''}
							name="StageId"
							label={t('Fields.StageId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputHint translationPath="ServiceTask.StageId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Workflow */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						<TextField
							disabled={true}
							value={formData.WorkflowIdLabel || ''}
							name="WorkflowId"
							label={t('Fields.WorkflowId')}
							fullWidth
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<InputLinkButton
											path={PATHS.workflow}
											documentId={formData.WorkflowId}
											documentLabel={formData.WorkflowIdLabel}
										/>
										<InputHint translationPath="ServiceTask.WorkflowId" />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{/* Project */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('Project') && (
							<TextField
								disabled={true}
								value={formData.ProjectLabel || ''}
								name="Project"
								label={t('Fields.Project')}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputLinkButton
												path={PATHS.project}
												documentId={formData.Project}
												documentLabel={formData.ProjectLabel}
											/>
											<InputHint translationPath="ServiceTask.Project" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ForeignNumber  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ForeignNumber') && (
							<TextField
								disabled={!isEditable('ForeignNumber')}
								value={formData.ForeignNumber || ''}
								name="ForeignNumber"
								label={t('Fields.ForeignNumber')}
								error={fieldsError.includes('ForeignNumber')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.ForeignNumber" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ReportingPerson  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ReportingPerson') && (
							<TextField
								disabled={!isEditable('ReportingPerson')}
								value={formData.ReportingPerson || ''}
								name="ReportingPerson"
								label={t('Fields.ReportingPerson')}
								error={fieldsError.includes('ReportingPerson')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.ReportingPerson" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* ReportingEmail  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('ReportingEmail') && (
							<TextField
								disabled={!isEditable('ReportingEmail')}
								value={formData.ReportingEmail || ''}
								name="ReportingEmail"
								label={t('Fields.ReportingEmail')}
								error={fieldsError.includes('ReportingEmail')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.ReportingEmail" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* RealizationDateTimeStart  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('RealizationDateTimeStart') && (
							<TextField
								disabled={!isEditable('RealizationDateTimeStart')}
								value={formData.RealizationDateTimeStart || ''}
								name="RealizationDateTimeStart"
								label={t('Fields.RealizationDateTimeStart')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('RealizationDateTimeStart')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.RealizationDateTimeStart" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* RealizationDateTimeEnd  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('RealizationDateTimeEnd') && (
							<TextField
								disabled={!isEditable('RealizationDateTimeEnd')}
								value={formData.RealizationDateTimeEnd || ''}
								name="RealizationDateTimeEnd"
								label={t('Fields.RealizationDateTimeEnd')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('RealizationDateTimeEnd')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.RealizationDateTimeEnd" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
					{/* InvestorReceiptDate  */}
					<Grid xs={12} sm={6} md={3} lg={3}>
						{isVisible('InvestorReceiptDate') && (
							<TextField
								disabled={!isEditable('InvestorReceiptDate')}
								value={formData.InvestorReceiptDate || ''}
								name="InvestorReceiptDate"
								label={t('Fields.InvestorReceiptDate')}
								InputLabelProps={{ shrink: true }}
								type="date"
								error={fieldsError.includes('InvestorReceiptDate')}
								onChange={handleInputChange}
								fullWidth
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<InputHint translationPath="ServiceTask.InvestorReceiptDate" />
										</InputAdornment>
									),
								}}
							/>
						)}
					</Grid>
				</Grid>
			</div>
			<ContractorForm
				documentId={id}
				contractorId={formData.Contractor}
				contractorLabel={formData.ContractorLabel}
				readOnly={!isEditable('Contractor')}
			/>
			<div className="w-full pb-4">
				<TabContext value={selectedTab}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<TabList onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
							{tabs.map((el, index) => {
								if (el.isVisible) {
									return <Tab key={index} label={el.label} value={index.toString()} />
								} else return null
							})}
						</TabList>
					</Box>
					{tabs.map((el, index) => {
						if (el.isVisible) {
							return (
								<TabPanel key={index} className="py-2.5 px-0" value={index.toString()}>
									{el.renderValue}
								</TabPanel>
							)
						} else return null
					})}
				</TabContext>
			</div>
		</>
	)
}

export default Form

const TAB_LOCAL_STORAGE_NAME = 'SERVICE_TASK_FORM_SELECTED_TAB'
