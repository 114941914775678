import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import SyncIcon from '@mui/icons-material/Sync'
import { GridToolbarContainer } from '@mui/x-data-grid-premium'

import { SettingsMenu, TooltipButton } from 'components/shared'
import { useAppSelector } from 'features'
import { IRealizationBudgetEntry, IServiceTask } from 'models'
import { enqueueSnackbar } from 'notistack'
import { useServiceTaskBudgetEntriesService } from 'services'

import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	getData: () => void
	apiRef: { current: any }
	readOnly: boolean
	data: IRealizationBudgetEntry[]
	refreshDocumentData: () => void
}

const Toolbar = ({ getData, apiRef, readOnly, data, refreshDocumentData }: Props) => {
	const { documentData } = useAppSelector(state => state.documentData)
	const { copiedDocumentData } = useAppSelector(state => state.linkDocuments)

	const { t } = useTranslation()

	const { addServiceTaskBudgetEntries, showSuccessInfo } = useServiceTaskBudgetEntriesService()

	const handleUpdateList = () => getData()

	const handleAddEntry = async () => {
		const serviceTaskData = documentData as IServiceTask

		if (!copiedDocumentData.id || copiedDocumentData.documentType !== 'ProjectBudget') {
			enqueueSnackbar(`${t('LinkDocumentDialog.CopyBudgetEntryDataFirst')} ${serviceTaskData.ProjectLabel || ''}`, {
				variant: 'warning',
				persist: true,
			})
			return
		}

		try {
			await addServiceTaskBudgetEntries(serviceTaskData.Id, copiedDocumentData.id)

			if (!data.length) {
				refreshDocumentData()
			}

			showSuccessInfo('saved')
			getData()
		} catch (err) {
			console.error(err)
		}
	}

	return (
		<GridToolbarContainer className="items-center justify-end m-1.5">
			<div>
				<TooltipButton title="general.Refresh" onClick={handleUpdateList} IconComponent={SyncIcon} fontSize="medium" />
				{!readOnly && (
					<TooltipButton title="general.Add" onClick={handleAddEntry} IconComponent={AddIcon} fontSize="medium" />
				)}
				<SettingsMenu apiRef={apiRef} initialState={INITIALSTATE} contextName={CONTEXT_STATE_NAME} />
			</div>
		</GridToolbarContainer>
	)
}

export default Toolbar
