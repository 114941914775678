import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ChangeHistory, DocumentFormSaveButton, FormSkeletonLoading } from 'components/shared'
import { useAppSelector, useDocumentFormSaveButtonActions, usePremisesActions } from 'features'
import { IPremises } from 'models'
import { usePremisesService } from 'services'

import Form from './Form'

type Props = {
	id: string
	readOnly: boolean
}

const BasicInfo = ({ id, readOnly }: Props) => {
	const { premisesData, formLoading } = useAppSelector(state => state.premises)
	const { setPremisesData } = usePremisesActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()

	const { getSinglePremises, updatePremises } = usePremisesService()

	const { t } = useTranslation()

	const refreshPremisesData = async () => {
		try {
			const response = await getSinglePremises(id)
			setPremisesData(response)
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		refreshPremisesData()
		setIsSaveButtonDisabled(true)
	}, [id])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={10} tabs={2} />
	}

	return (
		<>
			<div className="w-full flex justify-end items-center p-4 pb-0">
				<ChangeHistory
					contextId={id}
					translationFile="premises"
					title={`${t('SinglePremises', { ns: 'routes' })} ${premisesData?.FlatNumber}`}
					translationPath="Fields"
				/>
				<DocumentFormSaveButton
					documentId={id}
					data={premisesData}
					updateDataFunction={updatePremises}
					getDataFunction={refreshPremisesData}
				/>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<Form
					formData={premisesData as IPremises}
					id={id}
					readOnly={readOnly}
					refreshPremisesData={refreshPremisesData}
				/>
			</div>
		</>
	)
}

export default BasicInfo
