import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { IPremises } from 'models'

type InitialState = {
	premisesId: string
	premisesData: IPremises | null
	loading: boolean
	formLoading: boolean
}

const initialState: InitialState = {
	premisesId: '',
	premisesData: null,
	loading: true,
	formLoading: true,
}

export const premises = createSlice({
	name: 'premises',
	initialState,
	reducers: {
		setPremisesId: (state, action: PayloadAction<string>) => {
			state.premisesId = action.payload
		},
		setPremisesData: (state, action: PayloadAction<IPremises>) => {
			state.premisesData = action.payload
		},
		updatePremisesData: (state, action: PayloadAction<{ fieldName: string; value: any }[]>) => {
			action.payload.forEach(({ fieldName, value }) => {
				state.premisesData = {
					...state.premisesData,
					[fieldName]: value,
				} as IPremises
			})
		},
		updateLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		updateFormLoading: (state, action: PayloadAction<boolean>) => {
			state.formLoading = action.payload
		},
	},
})

export const premisesActions = premises.actions

export default premises.reducer
