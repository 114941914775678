import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import Backend from 'i18next-http-backend'

const getLanguage = localStorage.getItem('language')

i18n
	.use(initReactI18next)
	.use(Backend)
	.init({
		debug: false,

		lng: getLanguage || 'pl',

		ns: [
			'translation',
			'navigation',
			'project',
			'attachments',
			'routes',
			'administration',
			'offer',
			'contract',
			'dictionaries',
			'order',
			'processing-protocol',
			'investor-processing-protocol',
			'debit-note',
			'credit-note',
			'invoice',
			'goods-received-note',
			'demand',
			'synchronization',
			'incoming-letter',
			'notifications',
			'balancing-document',
			'common-task',
			'hints',
			'premises',
			'deposit-payment',
			'service-task',
		],
		defaultNS: 'translation',

		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
