import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ChangeHistory, DocumentFormSaveButton, FormSkeletonLoading, LinkCellRender } from 'components/shared'
import { PATHS } from 'data'
import { useAppSelector, useBudgetEntryActions, useDocumentFormSaveButtonActions } from 'features'
import { IRealizationBudgetEntry } from 'models'
import { useRealizationBudgetService } from 'services'

import Form from './Form'

type Props = {
	budgetEntryId: string
}

const BasicInfo = ({ budgetEntryId }: Props) => {
	const { budgetEntryData, formLoading } = useAppSelector(state => state.budgetEntryData)

	const { setBudgetEntryData } = useBudgetEntryActions()
	const { setIsSaveButtonDisabled } = useDocumentFormSaveButtonActions()
	const { getBudgetEntry, updateRow } = useRealizationBudgetService()

	const { t } = useTranslation('project')

	const refreshBudgetEntryData = async () => {
		try {
			const response = await getBudgetEntry(budgetEntryId)
			setBudgetEntryData(response)
		} catch (err) {
			console.error(err)
		}
	}
	
	useEffect(() => {
		refreshBudgetEntryData()
		setIsSaveButtonDisabled(true)
	}, [budgetEntryId])

	if (formLoading) {
		return <FormSkeletonLoading firstRowInputs={8} tabs={2} />
	}
	
	return (
		<>
			<div className="w-full flex justify-between items-center p-4 pb-0">
				<div>
					<p>
						<LinkCellRender
							path={PATHS.project}
							id={budgetEntryData?.ProjectId as string}
							documentLabel={budgetEntryData?.ProjectIdLabel as string}
							celllabel={`${t('BudgetTabGeneral.GroupingColumn')} ${budgetEntryData?.NumberString} - ${budgetEntryData?.ProjectIdLabel || ''}`}
							state={{ tab: 1, rowIdToFocus: budgetEntryId }}
						/>
					</p>
				</div>
				<div>
					<ChangeHistory
						contextId={budgetEntryId}
						translationFile="project"
						title={`${t('BudgetTabGeneral.GroupingColumn')} ${
							budgetEntryData?.NumberString
						}, ${budgetEntryData?.Description}`}
						translationPath="RealizationBudgetTab.Columns"
					/>
					<DocumentFormSaveButton
						documentId={budgetEntryId}
						data={budgetEntryData}
						updateDataFunction={updateRow}
						getDataFunction={refreshBudgetEntryData}
					/>
				</div>
			</div>
			<div className="w-full flex flex-col p-4 pt-0">
				<Form formData={budgetEntryData as IRealizationBudgetEntry} />
			</div>
		</>
	)
}

export default BasicInfo
