import { GridInitialState } from '@mui/x-data-grid-premium'

export const INITIALSTATE: GridInitialState = {
	rowGrouping: {
		model: [],
	},
	pinnedColumns: {
		left: [],
		right: ['actions'],
	},
	columns: {
		columnVisibilityModel: {},
		orderedFields: [
			'FlatNumber',
			'FlatNumberBuilding',
			'Staircase',
			'Floor',
			'Area',
			'PartsCount',
			'ProjectIdLabel',
			'AddressIdLabel',
			'ContractualWarrantyDate',
			'IndividualWarrantyDate',
			'HandoverToInvestorDate',
			'HandoverToMainOwnerDate',
			'PremisesTypeIdLabel',
			'PremisesStatusIdLabel',
			'PremisesHandoverStatusIdLabel',
			'TenantChanges',
			'WarrantyLoss',
			'WarrantyChange',
			'actions',
		],
		dimensions: {
			FlatNumber: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			FlatNumberBuilding: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			Staircase: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			Floor: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			PartsCount: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			ProjectIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			AddressIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 450,
				flex: 0,
			},
			ContractualWarrantyDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			IndividualWarrantyDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			HandoverToInvestorDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			HandoverToMainOwnerDate: {
				maxWidth: -1,
				minWidth: 50,
				width: 250,
				flex: 0,
			},
			Area: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			PremisesTypeIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			PremisesStatusIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			PremisesHandoverStatusIdLabel: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			TenantChanges: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			WarrantyLoss: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			WarrantyChange: {
				maxWidth: -1,
				minWidth: 50,
				width: 200,
				flex: 0,
			},
			actions: {
				maxWidth: -1,
				minWidth: 50,
				width: 100,
			},
		},
	},
	preferencePanel: {
		open: false,
	},
	filter: {
		filterModel: {
			items: [],
			quickFilterValues: [],
		},
	},
	sorting: {
		sortModel: [],
	},
	pagination: {
		pageSize: 100,
		page: 0,
	},
	aggregation: {
		model: {},
	},
}

export const CONTEXT_STATE_NAME = 'premisesListDataGridState'
export const SELECTED_PROJECT_SESSION_STORAGE_NAME = 'PREMISES_SELECTED_PROJECT'
export const SELECTED_ADDRESS_SESSION_STORAGE_NAME = 'PREMISES_SELECTED_ADDRESS'
