import { bindActionCreators } from '@reduxjs/toolkit'

import { useDispatch } from 'react-redux'

import {
	autocompleteInputActions,
	budgetEntryActions,
	businessDataActions,
	calendarActions,
	documentActionsStateActions,
	documentContractorActions,
	documentDataActions,
	documentFormSaveButtonActions,
	linkDocumentActions,
	notificationsActions,
	premisesActions,
	previousFilterStateDataActions,
	projectDataActions,
	routingListActions,
} from './slices'

// Documents
const useDocumentDataActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(documentDataActions, dispatch)
}

const useBudgetEntryActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(budgetEntryActions, dispatch)
}

const usePreviousFilterStateDataActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(previousFilterStateDataActions, dispatch)
}

const usePremisesActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(premisesActions, dispatch)
}

const useProjectDataActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(projectDataActions, dispatch)
}

// Shared
const useAutocompleteInputActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(autocompleteInputActions, dispatch)
}

const useDocumentFormSaveButtonActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(documentFormSaveButtonActions, dispatch)
}

const useDocumentContractorActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(documentContractorActions, dispatch)
}

const useBusinessDataActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(businessDataActions, dispatch)
}

const useDocumentActionsStateActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(documentActionsStateActions, dispatch)
}

const useLinkDocumentsActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(linkDocumentActions, dispatch)
}

const useNotificationsActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(notificationsActions, dispatch)
}

const useRoutingListActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(routingListActions, dispatch)
}

const useCalendarActions = () => {
	const dispatch = useDispatch()

	return bindActionCreators(calendarActions, dispatch)
}

export {
	useDocumentDataActions,
	useAutocompleteInputActions,
	useDocumentFormSaveButtonActions,
	useDocumentContractorActions,
	useBusinessDataActions,
	useDocumentActionsStateActions,
	useLinkDocumentsActions,
	useNotificationsActions,
	useRoutingListActions,
	useCalendarActions,
	useBudgetEntryActions,
	usePreviousFilterStateDataActions,
	usePremisesActions,
	useProjectDataActions,
}
