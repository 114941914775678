import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremisesStatus } from 'models'

const usePremisesStatusService = () => {
	const { request, showSuccessInfo, error, fieldsError, clearError } = useHttp()

	const path = '/api/premisesStatus'

	const getPremisesStatusList = useCallback(async () => {
		const response = await request(`${path}`)
		return response
	}, [request])

	const addPremisesStatus = useCallback(
		async (data: IPremisesStatus) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSinglePremisesStatus = useCallback(
		async (id: string) => {
			const response = await request(`${path}/${id}`)
			return response
		},
		[request]
	)

	const updatePremisesStatus = useCallback(
		async (id: string, body: IPremisesStatus) => {
			await request(`${path}/${id}`, 'PATCH', body)
		},
		[request]
	)

	const deletePremisesStatus = useCallback(
		async (id: string) => {
			await request(`${path}/${id}`, 'DELETE')
		},
		[request]
	)

	return {
		error,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremisesStatusList,
		addPremisesStatus,
		getSinglePremisesStatus,
		updatePremisesStatus,
		deletePremisesStatus,
	}
}

export default usePremisesStatusService
