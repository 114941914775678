import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import {
	DataGridPremium,
	GridColumnHeaderParams,
	GridRenderCellParams,
	GridRowId,
	GridRowParams,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { ColumnHeaderRender, LinkCellRender, NoData, TooltipButton } from 'components/shared'
import { BudgetCostTypeDataSource, PATHS } from 'data'
import { useAppSelector } from 'features'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IContractEntry } from 'models'
import { useServiceTaskContractEntriesService } from 'services'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import Toolbar from './Toolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	documentId: string
	readOnly: boolean
}

const ContractEntries = ({ documentId, readOnly }: Props) => {
	const [data, setData] = useState<IContractEntry[]>([])
	const [dataLoading, setDataLoading] = useState(true)

	const { documentData } = useAppSelector(state => state.documentData)

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['contract', 'service-task', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { getServiceTaskContractEntries, deleteServiceTaskContractEntries, showSuccessInfo } =
		useServiceTaskContractEntriesService()

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteServiceTaskContractEntries(documentId, id as string)
			setData(prev => prev.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const columns: any = [
		{
			field: 'Name',
			headerName: t('ContractEntries.Name'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'ContractIdLabel',
			headerName: t('ContractEntries.ContractId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.contract}
					id={params.row.ContractId}
					documentLabel={params.row.ContractIdLabel}
					celllabel={params.row.ContractIdLabel}
				/>
			),
		},
		{
			field: 'CostType',
			headerName: t('ContractEntries.CostType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CostType || 'Undefined'
			},
			valueOptions: () =>
				BudgetCostTypeDataSource.map(el => {
					return {
						value: el.value,
						label: t(`ContractEntries.CostTypeEnums.${el.label}`),
					}
				}),
			valueFormatter: ({ value }: any) => {
				const option = BudgetCostTypeDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return t(`ContractEntries.CostTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'MeasurementUnitIdLabel',
			headerName: t('ContractEntries.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Amount',
			headerName: t('ContractEntries.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Price',
			headerName: t('ContractEntries.Price'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value, 4, 4)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Value',
			headerName: `${t('ContractEntries.Value')}`,
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'SecurityDepositAmountNWK',
			headerName: t('ContractEntries.SecurityDepositAmountNWK'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'SecurityDepositAmountUWiU',
			headerName: t('ContractEntries.SecurityDepositAmountUWiU'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			sortable: false,
			editable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'Parameters',
			headerName: t('ContractEntries.Parameters'),
			headerAlign: 'center',
			align: 'center',
			sortable: false,
			editable: true,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'BudgetEntryId',
			headerName: t('ContractEntries.BudgetEntryId'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			renderCell: (params: GridRenderCellParams<any>) => (
				<LinkCellRender
					path={PATHS.project}
					id={documentData?.Project as string}
					documentLabel={documentData?.ProjectLabel as string}
					celllabel={params.row.BudgetEntryIdLabel}
					state={{ tab: 1, rowIdToFocus: params.value }}
				/>
			),
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Contract.ContractEntries" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				if (readOnly) return []

				return [
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params.id)}
						IconComponent={DeleteIcon}
						disabled={readOnly}
					/>,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = async () => {
		try {
			setDataLoading(true)
			const response = await getServiceTaskContractEntries(documentId)
			setData(response)
		} catch (err) {
			console.error(err)
		} finally {
			setDataLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [documentId])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<DataGridPremium
			apiRef={apiRef}
			editMode="row"
			columns={orderedColumns}
			rows={data}
			getRowId={row => row.Id}
			loading={dataLoading || dataGridStateLoading}
			sx={{ minHeight: '500px', height: 'calc(100vh - 250px)', border: 'none' }}
			components={{
				Toolbar: Toolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					apiRef,
					getData,
					readOnly,
					data,
				},
			}}
			initialState={INITIALSTATE}
			rowHeight={35}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default ContractEntries
