import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import HistoryIcon from '@mui/icons-material/History'
import SaveIcon from '@mui/icons-material/Save'
import {
	DataGridPremium,
	GridCellParams,
	GridColumnHeaderParams,
	GridEventListener,
	GridRowId,
	GridRowModel,
	GridRowModes,
	GridRowModesModel,
	GridRowParams,
	MuiEvent,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { ColumnHeaderRender, NoData, TooltipButton } from 'components/shared'
import ChangeHistoryDialog from 'components/shared/change-history/ChangeHistoryDialog'
import { useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IPremisesPart } from 'models'
import { usePremisesPartService, usePremisesPartTypeService } from 'services'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import Toolbar from './Toolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	premisesId: string
	readOnly: boolean
	refreshPremisesData: () => void
}

const Parts = ({ premisesId, readOnly, refreshPremisesData }: Props) => {
	const [data, setData] = useState<IPremisesPart[]>([])
	const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false)
	const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({})
	const [dataLoading, setDataLoading] = useState(true)

	const [partTypeOptions, setPartTypeOptions] = useState<any[]>([])

	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)
	const [selectedEntryId, setSelectedEntryId] = useState('')
	const [selectedEntryName, setSelectedEntryName] = useState('')

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['premises', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { getPremisesPart, updatePremisesPart, addPremisesPart, deletePremises, showSuccessInfo } =
		usePremisesPartService()
	const { getPremisesPartTypeList } = usePremisesPartTypeService()

	const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
		event.defaultMuiPrevented = true
	}

	const handleCellDoubleClick = (params: GridCellParams, e: any) => {
		const Id = params.row.Id

		if (readOnly) {
			return
		}

		setIsActionButtonDisabled(true)
		setRowModesModel({ ...rowModesModel, [Id]: { mode: GridRowModes.Edit } })
	}

	const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
		event.defaultMuiPrevented = true
	}

	const handleSaveClick = (id: GridRowId) => () => {
		setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
		setIsActionButtonDisabled(false)
	}

	const handleCancelClick = (id: GridRowId) => () => {
		setIsActionButtonDisabled(false)
		setRowModesModel({
			...rowModesModel,
			[id]: { mode: GridRowModes.View, ignoreModifications: true },
		})

		const editedRow = data.find((row: any) => row.Id === id)
		if (editedRow!.isNew) {
			setData(data.filter((row: any) => row.Id !== id))
		}
	}

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deletePremises(id as string)
			setData(data.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
			refreshPremisesData()
		} catch (err) {
			console.error(err)
		}
	}

	const handleShowHistory = (params: any) => () => {
		setIsChangeHistoryDialogOpen(true)
		setSelectedEntryId(params.id as string)
		setSelectedEntryName(`${params.row.Description}`)
	}

	const processRowUpdate = async (newRow: GridRowModel<IPremisesPart>) => {
		if (newRow.isNew) {
			let createdRow: any = newRow
			delete newRow.isNew
			try {
				const response = await addPremisesPart(newRow as IPremisesPart)
				createdRow = response
				setData(data.map((row: IPremisesPart) => (row.Id === newRow.Id ? response : row)))
				showSuccessInfo('saved')
				getData()
				refreshPremisesData()
			} catch (err) {
				console.error(err)
			}
			return createdRow
		} else {
			const updatedRow = { ...newRow, isNew: false }
			try {
				await updatePremisesPart(newRow.Id, newRow as IPremisesPart)
				setData(data.map((row: IPremisesPart) => (row.Id === newRow.Id ? updatedRow : row)))
				showSuccessInfo('saved')
				getData()
				refreshPremisesData()
			} catch (err) {
				console.error(err)
			}
			return updatedRow
		}
	}

	const columns: any = [
		{
			field: 'Description',
			headerName: t('PremisesPart.Description'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: true,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Premises.PremisesPart" />
			),
		},
		{
			field: 'PremisesPartTypeId',
			headerName: t('PremisesPart.PremisesPartTypeId'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.PremisesPartTypeId || ''
			},
			valueOptions: partTypeOptions,
			valueFormatter: ({ value }: any) => {
				const option = partTypeOptions.find((opt: any) => opt.value === value)
				if (option) {
					return option.label
				} else {
					return ''
				}
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Premises.PremisesPart" />
			),
		},
		{
			field: 'Area',
			headerName: t('PremisesPart.Area'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: true,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Premises.PremisesPart" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

				if (isInEditMode) {
					return [
						<TooltipButton title="general.Save" onClick={handleSaveClick(params.id)} IconComponent={SaveIcon} />,
						<TooltipButton title="general.Cancel" onClick={handleCancelClick(params.id)} IconComponent={CancelIcon} />,
					]
				} else {
					return [
						<TooltipButton
							title="ChangeHistory.ChangeHistory"
							onClick={handleShowHistory(params)}
							IconComponent={HistoryIcon}
							disabled={isActionButtonDisabled}
						/>,
						<TooltipButton
							title="general.Remove"
							onClick={handleDeleteClick(params.id)}
							IconComponent={DeleteIcon}
							disabled={isActionButtonDisabled || readOnly}
						/>,
					]
				}
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = async () => {
		try {
			setDataLoading(true)
			const response = await getPremisesPart(premisesId)
			setData(response)
		} catch (err) {
			console.error(err)
		} finally {
			setDataLoading(false)
		}
	}

	const getPartTypeOptions = async () => {
		try {
			const response = await getPremisesPartTypeList()
			setPartTypeOptions(response.map((el: any) => ({ value: el.Id, label: el.Name })))
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
		getPartTypeOptions()
	}, [])

	useEffect(() => {
		getData()
	}, [premisesId])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<>
			<DataGridPremium
				autoHeight
				apiRef={apiRef}
				editMode="row"
				columns={orderedColumns}
				rows={data}
				getRowId={row => row.Id}
				loading={dataLoading || dataGridStateLoading}
				onRowEditStart={handleRowEditStart}
				onRowEditStop={handleRowEditStop}
				processRowUpdate={processRowUpdate}
				rowModesModel={rowModesModel}
				onRowModesModelChange={newModel => setRowModesModel(newModel)}
				onCellDoubleClick={handleCellDoubleClick}
				experimentalFeatures={{ newEditingApi: true }}
				components={{
					Toolbar: Toolbar,
					NoRowsOverlay: () => <NoData />,
				}}
				componentsProps={{
					toolbar: {
						apiRef,
						setIsActionButtonDisabled,
						isActionButtonDisabled,
						setData,
						setRowModesModel,
						readOnly,
					},
				}}
				initialState={INITIALSTATE}
				rowHeight={35}
				localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
			/>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={selectedEntryId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={selectedEntryName}
					translationFile="Premises"
					translationPath="PremisesPart"
				/>
			)}
		</>
	)
}

export default Parts
