import { MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import { Tooltip } from '@mui/material'

import clsx from 'clsx'
import { TooltipButton } from 'components/shared'
import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import { SelectValues } from './PremisesList'
import { AddPremisesDialog } from './add-premises-dialog'

type Props = {
	address: SelectValues
	updateList: () => void
	disabled?: boolean
}

export type CreationType = 'addSingle' | 'addGroup' | 'generate' | null

const AddPremisesMenu = ({ address, updateList, disabled }: Props) => {
	const [isMenuOpened, setIsMenuOpened] = useState(false)
	const [isAddPremisesDialogOpened, setIsAddPremisesDialogOpened] = useState(false)
	const [selectedType, setSelectedType] = useState<CreationType>(null)
	const menuRef = useRef<HTMLDivElement>(null)

	const { t } = useTranslation('premises')

	const handleOpenMenu = (event: ReactMouseEvent<HTMLElement>) => setIsMenuOpened(true)

	const handleCloseMenu = () => setIsMenuOpened(false)

	const handleOptionClick = (callback: () => void) => {
		setIsAddPremisesDialogOpened(true)
		handleCloseMenu()
		callback()
	}

	const handleClickOutside = (event: MouseEvent) => {
		if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
			handleCloseMenu()
		}
	}

	const menuOptions = [
		{
			title: t('Other.AddSinglePremises'),
			description: t('Other.AddSinglePremisesDescription'),
			onClick: () => setSelectedType('addSingle'),
		},
		{
			title: t('Other.AddPremisesAsGroup'),
			description: t('Other.AddPremisesAsGroupDescription'),
			onClick: () => setSelectedType('addGroup'),
		},
		{
			title: t('Other.GeneratePremisesAsList'),
			description: t('Other.GeneratePremisesAsListDescription'),
			onClick: () => setSelectedType('generate'),
		},
	]

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	return (
		<>
			<TooltipButton
				title={disabled ? 'Other.ToAddPremisesChooseProjectAndAddress' : 'general.Add'}
				onClick={handleOpenMenu}
				IconComponent={AddIcon}
				fontSize="medium"
				disabled={disabled}
				translationFile={disabled ? 'premises' : 'translation'}
			/>
			<div
				ref={menuRef}
				className={clsx(
					'flex flex-col py-2',
					'absolute right-2 top-12 z-20 w-[250px]',
					'border-solid border-border-divider dark:border-border-darkDivider',
					'rounded bg-background-default dark:bg-background-darkHighlighted',
					{
						hidden: !isMenuOpened,
					}
				)}>
				{menuOptions.map((option, index) => (
					<Tooltip key={index} title={<TooltipTextWrapper title={option.description} />} placement="left" arrow>
						<div
							className={clsx('flex items-center cursor-pointer py-1.5 px-1', 'hover:bg-primary-main/10')}
							onClick={() => handleOptionClick(option.onClick)}>
							<span className="mx-2">{option.title}</span>
						</div>
					</Tooltip>
				))}
			</div>
			{isAddPremisesDialogOpened && (
				<AddPremisesDialog
					isOpen={isAddPremisesDialogOpened}
					setIsOpen={setIsAddPremisesDialogOpened}
					address={address}
					type={selectedType}
					updateList={updateList}
				/>
			)}
		</>
	)
}

export default AddPremisesMenu
