import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import {
	DataGridPremium,
	DataGridPremiumProps,
	GridColumnHeaderParams,
	GridRowHeightParams,
	GridRowParams,
	GridRowTreeNodeConfig,
	useGridApiRef,
} from '@mui/x-data-grid-premium'

import { ColumnHeaderRender, NoData } from 'components/shared'
import { BudgetCostTypeDataSource, BudgetEntryCategoryDataSource } from 'data'
import { useDataGridRowFocus, useDataGridState } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IServiceBudget } from 'models'
import { useServiceBudgetService } from 'services'
import formatAmountNumberDataGrid from 'utils/formatAmountNumberDataGrid'
import formatNumberDataGrid from 'utils/formatNumberDataGrid'

import Actions from './Actions'
import Toolbar from './Toolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE, LOCAL_STORAGE_CONFIG_KEY } from './initial-state'

type Props = {
	projectId: string
}

const ServiceBudget = ({ projectId }: Props) => {
	const [data, setData] = useState<IServiceBudget[]>([])
	const [dataLoading, setDataLoading] = useState(true)

	const { getServiceBudget } = useServiceBudgetService()

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['project', 'translation'])

	const location = useLocation()

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { handleFocusOnSelectedRow } = useDataGridRowFocus(data, apiRef)

	const localStorageData = localStorage.getItem(LOCAL_STORAGE_CONFIG_KEY)

	const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
		headerName: t('BudgetTabGeneral.GroupingColumn') as string,
		width: 300,
		minWidth: 130,
		headerAlign: 'center',
	}

	const columns: any = [
		{
			field: 'Description',
			headerName: t('RealizationBudgetTab.Columns.Description'),
			headerAlign: 'center',
			align: 'left',
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'CostType',
			headerName: t('RealizationBudgetTab.Columns.CostType'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.CostType || 'Undefined'
			},
			valueOptions: () =>
				BudgetCostTypeDataSource.map((el: any) => ({
					value: el.value,
					label: t(`RealizationBudgetTab.Columns.CostTypeEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = BudgetCostTypeDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return t(`RealizationBudgetTab.Columns.CostTypeEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'Category',
			headerName: t('RealizationBudgetTab.Columns.Category'),
			headerAlign: 'center',
			align: 'center',
			type: 'singleSelect',
			valueGetter: (params: any) => {
				return params.row.Category || 'Undefined'
			},
			valueOptions: () =>
				BudgetEntryCategoryDataSource.map((el: any) => ({
					value: el.value,
					label: t(`RealizationBudgetTab.Columns.CategoryEnums.${el.label}`),
				})),
			valueFormatter: ({ value }: any) => {
				const option = BudgetEntryCategoryDataSource.find((opt: any) => opt.value === value)
				if (option) {
					return t(`RealizationBudgetTab.Columns.CategoryEnums.${option.label}`)
				} else {
					return ''
				}
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'IndustryIdLabel',
			headerName: t('RealizationBudgetTab.Columns.IndustryId'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'MeasurementUnitIdLabel',
			headerName: t('RealizationBudgetTab.Columns.MeasurementUnitId'),
			headerAlign: 'center',
			align: 'center',
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'Amount',
			headerName: t('RealizationBudgetTab.Columns.Amount'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatAmountNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'Price',
			headerName: t('RealizationBudgetTab.Columns.Price'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value, 4, 4)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'Budget',
			headerName: t('RealizationBudgetTab.Columns.Budget'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'RealizationDateStart',
			headerName: t('RealizationBudgetTab.Columns.RealizationDateStart'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			type: 'date',
			valueGetter: ({ value }: any) => {
				const date = new Date(value)
				const dateTimestamp = Date.UTC(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate(),
					date.getUTCHours(),
					date.getUTCMinutes(),
					date.getUTCSeconds()
				)
				const newValue = dateTimestamp + 43200000
				if (value) {
					return new Date(newValue)
				}
			},
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'RealizationDateEnd',
			headerName: t('RealizationBudgetTab.Columns.RealizationDateEnd'),
			headerAlign: 'center',
			align: 'center',
			sortable: true,
			editable: false,
			type: 'date',
			valueGetter: ({ value }: any) => {
				const date = new Date(value)
				const dateTimestamp = Date.UTC(
					date.getUTCFullYear(),
					date.getUTCMonth(),
					date.getUTCDate(),
					date.getUTCHours(),
					date.getUTCMinutes(),
					date.getUTCSeconds()
				)
				const newValue = dateTimestamp + 43200000
				if (value) {
					return new Date(newValue)
				}
			},
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'RealizationLengthDays',
			headerName: t('RealizationBudgetTab.Columns.RealizationLengthDays'),
			headerAlign: 'center',
			align: 'center',
			type: 'number',
			valueGetter: (params: any) => {
				if (params.row.RealizationDateStart && params.row.RealizationDateEnd) {
					const dateStartTimestamp = Date.parse(params.row.RealizationDateStart)
					const dateEndTimestamp = Date.parse(params.row.RealizationDateEnd)
					const result = (dateEndTimestamp - dateStartTimestamp) / 86400000 + 1
					return result.toFixed()
				}
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'SecurityDepositInvoiceRetentionValueNWK',
			headerName: t('RealizationBudgetTab.Columns.SecurityDepositInvoiceRetentionValueNWK'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'SecurityDepositInvoiceRetentionValueUWiU',
			headerName: t('RealizationBudgetTab.Columns.SecurityDepositInvoiceRetentionValueUWiU'),
			headerAlign: 'center',
			align: 'right',
			type: 'number',
			valueFormatter: ({ value }: any) => {
				if (value == null) {
					return ''
				}

				return formatNumberDataGrid(value)
			},
			editable: false,
			sortable: false,
			renderHeader: (params: GridColumnHeaderParams) => (
				<ColumnHeaderRender params={params} translationPath="Project.ServiceBudget" />
			),
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				return [<Actions params={params} />]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = async () => {
		try {
			setDataLoading(true)
			const response = await getServiceBudget(projectId)

			setData(response)
		} catch (err) {
			console.error(err)
		} finally {
			setDataLoading(false)
		}
	}

	const isGroupExpandedByDefault: any = (node: GridRowTreeNodeConfig) => {
		if (localStorageData?.includes(node.id as string)) {
			return true
		} else {
			return false
		}
	}

	useEffect(() => {
		let storage: any
		if (localStorageData) {
			storage = JSON.parse(localStorageData)
		} else {
			storage = { rowsExpandedByDefault: [] }
		}
		apiRef.current.subscribeEvent('rowExpansionChange', node => {
			if (node.childrenExpanded) {
				storage?.rowsExpandedByDefault.push(node.id)
			} else {
				const deleteRowIdFromStorage = storage.rowsExpandedByDefault.filter((item: any) => {
					return item !== node.id
				})
				storage = {
					rowsExpandedByDefault: [...deleteRowIdFromStorage],
				}
			}
			localStorage.setItem(LOCAL_STORAGE_CONFIG_KEY, JSON.stringify(storage))
		})
	}, [apiRef])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		getData()
	}, [projectId])

	useEffect(() => {
		const rowIdToFocus = location.state?.rowIdToFocus
		if (rowIdToFocus && data) handleFocusOnSelectedRow(rowIdToFocus)
	}, [data, location.state])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<DataGridPremium
			apiRef={apiRef}
			editMode="row"
			getTreeDataPath={row => row.TreePath}
			treeData
			columns={orderedColumns}
			rows={data}
			loading={dataLoading || dataGridStateLoading}
			sx={{
				minHeight: '500px',
				height: 'calc(100vh - 230px)',
				border: 'none',
			}}
			groupingColDef={groupingColDef}
			getRowId={row => row.Id}
			experimentalFeatures={{ aggregation: true }}
			components={{
				Toolbar: Toolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					getData,
					projectId,
					apiRef,
				},
			}}
			sortingMode="server"
			initialState={INITIALSTATE}
			rowHeight={35}
			getRowHeight={(params: GridRowHeightParams) => {
				if (params.model.Description?.length > 50) {
					return 'auto'
				}
				return null
			}}
			getAggregationPosition={groupNode => (groupNode == null ? 'footer' : null)}
			isGroupExpandedByDefault={isGroupExpandedByDefault}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default ServiceBudget
