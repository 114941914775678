import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import { DataGridPremium, GridRowId, GridRowParams, useGridApiRef } from '@mui/x-data-grid-premium'

import { NoData, TooltipButton } from 'components/shared'
import { useDataGridState, useHandleRowClick } from 'hooks'
import useLanguage from 'hooks/UseLanguge'
import { IEntriesAnnexesState, IPremises } from 'models'
import { useContractEntryPremisesService } from 'services'

import Toolbar from './Toolbar'
import { CONTEXT_STATE_NAME, INITIALSTATE } from './initial-state'

type Props = {
	readOnly: boolean
	entryData: IEntriesAnnexesState
}

const Premises = ({ readOnly, entryData }: Props) => {
	const [data, setData] = useState<IPremises[]>([])
	const [dataLoading, setDataLoading] = useState(true)

	const { dataGridStateLoading, getDataGridState, dataGridState, getSortedColumns } = useDataGridState()

	const { t } = useTranslation(['premises', 'contract', 'translation'])

	const { dataGridLanguage } = useLanguage()

	const apiRef = useGridApiRef()

	const { handleRowClick } = useHandleRowClick()

	const { getContractEntryPremises, deleteContractEntryPremises, showSuccessInfo } = useContractEntryPremisesService()

	const handleDeleteClick = (id: GridRowId) => async () => {
		try {
			await deleteContractEntryPremises(entryData.id, id as string)
			setData(prev => prev.filter((row: any) => row.Id !== id))
			showSuccessInfo('deleted')
		} catch (err) {
			console.error(err)
		}
	}

	const columns: any = [
		{
			field: 'AddressIdLabel',
			headerName: t('Fields.AddressId'),
			headerAlign: 'center',
			align: 'left',
			sortable: true,
			editable: false,
		},
		{
			field: 'FlatNumber',
			headerName: t('Fields.FlatNumber'),
			headerAlign: 'center',
			align: 'right',
			sortable: true,
			editable: false,
		},
		{
			field: 'FlatNumberBuilding',
			headerName: t('Fields.FlatNumberBuilding'),
			headerAlign: 'center',
			align: 'right',
			sortable: true,
			editable: false,
		},
		{
			field: 'actions',
			type: 'actions',
			headerName: '',
			cellClassName: 'actions',
			getActions: (params: GridRowParams) => {
				if (readOnly) return []

				return [
					<TooltipButton
						title="general.Remove"
						onClick={handleDeleteClick(params.id)}
						IconComponent={DeleteIcon}
						disabled={readOnly}
					/>,
				]
			},
		},
	]

	const orderedColumns = getSortedColumns(columns)

	const getData = async () => {
		try {
			setDataLoading(true)
			const response = await getContractEntryPremises(entryData.id)
			setData(response)
		} catch (err) {
			console.error(err)
		} finally {
			setDataLoading(false)
		}
	}

	useEffect(() => {
		getData()
	}, [entryData.id])

	useEffect(() => {
		getDataGridState(CONTEXT_STATE_NAME)
	}, [])

	useEffect(() => {
		if (dataGridState) {
			apiRef.current.restoreState(dataGridState)
		}
	}, [dataGridState])

	return (
		<DataGridPremium
			apiRef={apiRef}
			editMode="row"
			columns={orderedColumns}
			rows={data}
			getRowId={row => row.Id}
			onRowClick={params =>
				handleRowClick(params, '/premises', params.row.FlatNumber ? 'FlatNumber' : 'FlatNumberBuilding')
			}
			loading={dataLoading || dataGridStateLoading}
			experimentalFeatures={{ newEditingApi: true }}
			sx={{ minHeight: '500px', height: 'calc(100vh - 135px)' }}
			components={{
				Toolbar: Toolbar,
				NoRowsOverlay: () => <NoData />,
			}}
			componentsProps={{
				toolbar: {
					apiRef,
					getData,
					readOnly,
					entryId: entryData.id,
				},
			}}
			initialState={INITIALSTATE}
			rowHeight={35}
			localeText={dataGridLanguage.components.MuiDataGrid.defaultProps.localeText}
		/>
	)
}

export default Premises
