import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import HistoryIcon from '@mui/icons-material/History'
import { IconButton, Tooltip } from '@mui/material'

import TooltipTextWrapper from 'utils/TooltipTextWrapper'

import ChangeHistoryDialog from './ChangeHistoryDialog'

interface Props {
	contextId: string
	title: string
	translationFile: string
	translationPath: string
}

const ChangeHistory = ({ contextId, title, translationFile, translationPath }: Props) => {
	const [isChangeHistoryDialogOpen, setIsChangeHistoryDialogOpen] = useState(false)

	const { t } = useTranslation()

	return (
		<>
			<Tooltip title={<TooltipTextWrapper title={t('ChangeHistory.ChangeHistory')} />} placement="top" arrow>
				<IconButton sx={{ mr: '8px' }} onClick={() => setIsChangeHistoryDialogOpen(true)}>
					<HistoryIcon color="primary" />
				</IconButton>
			</Tooltip>
			{isChangeHistoryDialogOpen && (
				<ChangeHistoryDialog
					contextId={contextId}
					isOpen={isChangeHistoryDialogOpen}
					setIsOpen={setIsChangeHistoryDialogOpen}
					title={title}
					translationFile={translationFile}
					translationPath={translationPath}
				/>
			)}
		</>
	)
}

export default ChangeHistory
