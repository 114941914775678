import { useCallback } from 'react'

import { useHttp } from 'hooks/http.hook'
import { IPremisesAddress } from 'models'
import { PREMISES_ADDRESSES_ENDPOINT } from 'services/data'

const usePremisesAddressService = () => {
	const { request, showSuccessInfo, error, errorStatus, fieldsError, clearError } = useHttp()

	const path = PREMISES_ADDRESSES_ENDPOINT

	const getPremisesAddresses = useCallback(
		async (projectId: string) => {
			const response = await request(`${path}?projectId=${projectId}`)
			return response
		},
		[request]
	)

	const addPremisesAddress = useCallback(
		async (data: IPremisesAddress) => {
			const response = await request(`${path}`, 'POST', data)
			return response
		},
		[request]
	)

	const getSinglePremisesAddress = useCallback(
		async (addressId: string) => {
			const response = await request(`${path}/${addressId}`)
			return response
		},
		[request]
	)

	const updatePremisesAddress = useCallback(
		async (addressId: string, data: IPremisesAddress) => {
			await request(`${path}/${addressId}`, 'PATCH', data)
		},
		[request]
	)

	const deletePremisesAddress = useCallback(
		async (addressId: string) => {
			await request(`${path}/${addressId}`, 'DELETE')
		},
		[request]
	)

	const createPremisesByList = useCallback(
		async (addressId: string, data: string[]) => {
			await request(`${path}/createSeriesByList/${addressId}`, 'POST', data)
		},
		[request]
	)

	const createPremisesFromTo = useCallback(
		async (addressId: string, flatNumberFrom: number, flatNumberTo: number) => {
			await request(
				`${path}/createSeriesFromTo/${addressId}?flatNumberFrom=${flatNumberFrom}&flatNumberTo=${flatNumberTo}`,
				'POST'
			)
		},
		[request]
	)

	return {
		error,
		errorStatus,
		fieldsError,
		clearError,
		showSuccessInfo,
		getPremisesAddresses,
		addPremisesAddress,
		getSinglePremisesAddress,
		updatePremisesAddress,
		deletePremisesAddress,
		createPremisesByList,
		createPremisesFromTo,
	}
}

export default usePremisesAddressService
